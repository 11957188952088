<mat-dialog-content aria-modal="true" class="d-flex flex-col" role="dialog">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">Update
                        <ng-container *ngIf="profile">{{ profile.first_name || profile.email }}</ng-container>
                        's Password
                    </h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button (keyup.enter)="this.dialogRef.close();" class="mat-dialog-close" mat-dialog-close mat-icon-button
                    matTooltip="Close Window">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="passwordForm" class="otherThirdGrid">
        <div class="flex-col">
            <mat-form-field appearance="outline" class="w-100 mb-10" color="primary">
                <mat-label>New Password</mat-label>
                <input #password formControlName="password" matInput
                       type="password">
                <mat-error (click)="password.focus()"
                           *ngIf="passwordForm.controls['password'].hasError('required')">
                    This field is required
                </mat-error>
                <mat-error (click)="password.focus()"
                           *ngIf="passwordForm.controls['password'].hasError('pattern')">
                    The password must match all the requirements
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" class="w-100 mb-10" color="primary">
                <mat-label>Confirm New Password</mat-label>
                <input #confirmPassword formControlName="confirmPassword"
                       matInput type="password">
                <mat-error (click)="confirmPassword.focus()"
                           *ngIf="passwordForm.controls['confirmPassword'].hasError('required')">
                    This field is required
                </mat-error>
                <mat-error (click)="confirmPassword.focus()"
                           *ngIf="passwordForm.controls['confirmPassword'].hasError('pattern')">
                    The password must match all the requirements
                </mat-error>
                <mat-hint (click)="confirmPassword.focus()" *ngIf="password.value != confirmPassword.value && !passwordForm.controls['confirmPassword'].hasError('pattern')"
                          class="errorMsg">
                    Passwords do not match
                </mat-hint>
            </mat-form-field>
        </div>
        <app-password-validator [matching]="password.value != confirmPassword.value"
                                [password]="password.value"></app-password-validator>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="flex-space-around">
    <button (keyup.enter)="this.dialogRef.close();" [disabled]="loading" color="warn" mat-dialog-close
            mat-raised-button>
        Cancel
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
    <button (click)="savePassword()" [disabled]="passwordForm.invalid || loading"
            color="primary" mat-raised-button>
        Save
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
</mat-dialog-actions>

