import {AfterViewInit, Component, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import {AdminService} from "../../_services/admin.service";
import {SnackbarService} from "../../_services/snackbar.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EditNameComponent} from "../shared/edit-name/edit-name.component";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-cohorts',
    templateUrl: './cohorts.component.html',
    styleUrl: './cohorts.component.scss'
})
export class CohortsComponent implements AfterViewInit {
    displayedColumns: string[] = ['name', 'date', 'view'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
    @ViewChild(MatSort) sort: MatSort | null = null;
    limit: number = 1000;
    page: number = 0;
    count: number = 0;
    loading: boolean = true;
    typingTimer: any;
    searchTerm: any;
    direction: string | undefined = 'desc';
    order_by: string | undefined = 'name';
    matSortDefault: string = 'name';
    matSortDirectionDefault: "asc" | "desc" = "desc";

    constructor(private adminService: AdminService,
                private route: ActivatedRoute,
                private router: Router,
                private snackbarService: SnackbarService,
                public dialog: MatDialog) {
        route.queryParams.subscribe(
            (data: any): void => {
                if (data['direction']) {
                    this.direction = data['direction']
                    if (this.direction == 'asc' || this.direction == 'desc') {
                        this.matSortDirectionDefault = this.direction;
                    }
                }

                if (data['order_by']) {
                    this.order_by = data['order_by']
                    if (this.order_by) {
                        if (this.order_by == 'created_date') {
                            this.matSortDefault = 'date'
                        }
                    }
                }

                if (data['page']) {
                    this.page = data['page']
                }
                if (data['limit']) {
                    this.limit = data['limit']
                }
                if (data['searchTerm']) {
                    this.searchTerm = data['searchTerm']
                }
            })
        // Assign the data to the data source for the table to render
        this.getCohorts();
    }


    getCohorts(): void {
        this.loading = true;
        let filters: any = {
            order_by: null,
            direction: null,
        }
        if (this.order_by && this.direction) {
            filters.order_by = this.order_by
        }
        if (this.direction) {
            filters.direction = this.direction
        }
        filters.page = this.page;
        filters.limit = this.limit;
        filters.searchTerm = this.searchTerm;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: filters,
            queryParamsHandling: 'merge',
        });
        this.adminService.getCohorts(this.page, this.limit, this.searchTerm, filters).subscribe(r => {
            this.count = r.count;
            this.dataSource = new MatTableDataSource(r.results);
            this.loading = false;
        }, e => {
            this.loading = false;
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout((): void => {
            this.getCohorts()
            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }
        }, 1000);
    }


    clearSearch(): void {
        this.searchTerm = '';
        this.getCohorts();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    sortData(event: Sort): void {
        this.order_by = event.active;
        if (this.order_by == 'date') {
            this.order_by = 'created_date'
        }
        this.direction = event.direction;
        this.getCohorts()
    }

    newCohortModal(): void {
        const dialogRef: MatDialogRef<EditNameComponent> = this.dialog.open(EditNameComponent, {
            data: {
                title: 'Create Cohort',
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.newCohort(resp).subscribe(r => {
                    this.snackbarService.openSnackBar('New cohort created', 'success');
                    this.getCohorts();
                }, e => {
                    console.error(e);
                    this.snackbarService.openSnackBar(e, 'error')
                })
            }
        });
    }
}
