<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Unresolved Feedback
        </h3>
    </div>

    <div class="largeContainer">
        <div class="flex-col align-items-end mb-10">
            <button [routerLink]="['/resolved']" class="w-content" color="primary" mat-raised-button>View resolved
                history
            </button>
            <ng-container *ngIf="this.checkedUnresolvedMedia.length > 0">
                <div class="align-items-center">
                    <button color="warn" mat-raised-button>Hide Media</button>
                </div>
                <div class="align-items-center pr-15">
                    <button color="primary" mat-raised-button>Delete Media</button>
                </div>
                <div class="align-items-center pr-15">
                    <button color="primary" mat-raised-button>Resolve</button>
                </div>
            </ng-container>
        </div>
        <div *ngIf="loading">
            <mat-progress-bar class="mb-10" color="primary" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="mat-elevation-z6 bg-white mb-20">
            <div class="flex-row gap-15 align-items-center flex-space-around filterItems fewItems">
                <mat-form-field class="noBottomPadding w-100">
                    <mat-label>Search Flagged Media</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getFlaggedMedia()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getFlaggedMedia()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search Flagged Media">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
                <mat-form-field class="w-100 noBottomPadding" color="primary">
                    <mat-label>Category</mat-label>
                    <select (ngModelChange)="getFlaggedMedia()" [(ngModel)]="category" matNativeControl>
                        <option selected>All</option>
                        <option *ngFor="let cat of categories" [selected]="category == cat"
                                [value]="cat">{{ cat }}
                        </option>
                    </select>
                </mat-form-field>

            </div>
            <app-flagged-table (checked)="checked($event)" [data]="unresolvedData"
                               [filter]="searchTerm" [count]="count"
                               [limit]="limit" [matSortDefault]="matSortDefault"
                               [matSortDirectionDefault]="matSortDirectionDefault"
                               (handlePageEvent)="pageChange($event)"
                               (orderChange)="changeOrder($event)"></app-flagged-table>
        </div>
    </div>
</main>
