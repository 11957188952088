<div #chatContent class="chatContent" id="chatContent">

    <div class="d-flex flex-row mb-10 gap-25" *ngFor="let message of this.comments;">
        <a class="d-flex userPicContainer"
           [class.clickable]="user.role.can_access_profiles"
           (click)="viewProfile(message.user.id)" (keyup.enter)="viewProfile(message.user.id)"
           *ngIf="message.user.id !== user.id">
            <img [alt]="message.user.full_name" *ngIf="message.user.thumbnail || message.user.photo"
                 [src]="message.user.thumbnail || message.user.photo || 'assets/images/blankProfile.png'"
                 class="userPic" loading="lazy" fetchPriority="low"
                 [matTooltip]="user.role.can_access_profiles ? 'View ' +  message.user.full_name + '\'s Profile' : message.user.full_name + '\'s Profile Picture'">
            <p class="initial m-10"
               *ngIf="!message.user.thumbnail && !message.user.photo">{{ message.user.first_name | slice: 0 : 1 }} {{ message.user.last_name | slice: 0 : 1 }}</p>
        </a>
        <div class="d-flex flex-grow flex-col mw-95">
            <div class="talk-bubble p-10">
                <strong class="primaryContrast f-14">{{ message.user.full_name }}</strong>
                <ng-container *ngIf="!message.edit">
                    <p class="m-0">{{ message.content }}</p>
                    <small *ngIf="receivedToday(message.date)"
                           class="extraSmall m-5">{{ message.date|date:"HH:mm" }}</small>
                    <small *ngIf="!receivedToday(message.date)"
                           class="extraSmall m-5">{{ message.date|date:"dd/MM/yyyy" }}</small>
                </ng-container>
                <ng-container *ngIf="message.edit">
                    <mat-form-field appearance="outline" class="w-100 bg-textarea-white"
                                    color="primary">
                                <textarea #messageInput aria-label="Comment content" matInput maxlength="400"
                                          name="message" [value]="message.content"></textarea>
                        <mat-hint align="end">{{ messageInput?.value?.length }} / 400</mat-hint>
                    </mat-form-field>
                    <div class="flex-row justify-content-center">
                        <button mat-raised-button color="accent"
                                (click)="editMessage(message, messageInput.value)">Save
                        </button>
                    </div>
                </ng-container>
            </div>
            <div class="flex-row flex-space-around"
                 *ngIf="message.user.id == user.id || this.user.role.role_type == 'admin'">
                <button mat-button (click)="message.edit = !message.edit">
                    <ng-container *ngIf="!message.edit">Edit</ng-container>
                    <ng-container *ngIf="message.edit">Cancel</ng-container>
                </button>
                <button mat-button color="warn" (click)="delete(message.id)">Delete</button>
            </div>
        </div>
        <div class="d-flex userPicContainer" *ngIf="message.user.id == user.id && !message.edit">
            <img [alt]="message.user.full_name" [src]="message.user.photo || 'assets/images/blankProfile.png'"
                 class="userPic" loading="lazy" fetchPriority="low"
                 matTooltip="{{message.user.full_name}}" *ngIf="message.user.thumbnail || message.user.photo">
            <p class="initial m-10"
               *ngIf="!message.user.thumbnail && !message.user.photo">{{ message.user.first_name | slice: 0 : 1 }} {{ message.user.last_name | slice: 0 : 1 }}</p>
        </div>
    </div>
    <div *ngIf="!reLoading && !endOfChat" class="d-flex justify-content-center mb-10">
        <button (click)="loadMore()" (keyup.enter)="loadMore()" class="smallBtn" color="accent" mat-raised-button>Load
            More
        </button>
    </div>
</div>
<mat-card-actions class="mb-0">
    <mat-card-footer *ngIf="reLoading">
        <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    </mat-card-footer>
    <form [formGroup]="messageForm" class="d-flex flex-row w-100 gap10">
        <div class="flex-auto">
            <mat-form-field appearance="outline" class="w-100"
                            color="primary">
                                <textarea #message aria-label="Comment content"
                                          formControlName="message" matInput maxlength="400"
                                          name="message"></textarea>
                <mat-hint align="end">{{ message?.value?.length }} / 400</mat-hint>
            </mat-form-field>
        </div>
        <div class="flex-col">
            <app-emoji-menu [form]="messageForm" [input]="'message'" matSuffix></app-emoji-menu>
            <button (click)="sendMessage()" (keyup.enter)="sendMessage()"
                    [disabled]="message && message.value && this.reLoading"
                    aria-label="Submit Comment"
                    class="ms-auto col-12 text-right"
                    color="primary" mat-icon-button>
                <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
        </div>
    </form>
</mat-card-actions>
