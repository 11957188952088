import {Component, ViewChild} from '@angular/core';
import {map, Observable, startWith} from "rxjs";
import {FormControl} from "@angular/forms";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {MemoryFormComponent} from "../shared/memory-form/memory-form.component";
import {AuthService} from "../_services/auth.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ContentService} from "../_services/content.service";
import {SnackbarService} from "../_services/snackbar.service";

@Component({
    selector: 'app-timeline',
    templateUrl: './timeline.component.html',
    styleUrl: './timeline.component.scss'
})
export class TimelineComponent {
    loading: boolean = true;
    typingTimer: any;
    dateTimer: any;
    searchTerm: any;
    @ViewChild('tagInput') tagInput: any;
    filteredTags: Observable<string[]> | any;
    tags: any[] = [];
    selectedTags: any[] = [];
    tagSearchControl: FormControl = new FormControl();
    permission: string | null = null;
    user: any;
    id: number = 0;
    years: any[] = []
    timeline: any;
    order: string = 'desc';
    startDateField: FormControl = new FormControl();
    endDateField: FormControl = new FormControl();
    startDate: any;
    endDate: any;
    invalidDate: boolean = false;
    favouritesThumbnail: string = '';
    readonly minDate: Date = new Date("1800-01-01");
    readonly maxDate: Date = new Date();

    constructor(public dialog: MatDialog,
                private route: ActivatedRoute,
                private authService: AuthService,
                private contentService: ContentService,
                private snackbarService: SnackbarService,
                private router: Router) {

        this.maxDate.setFullYear(this.maxDate.getFullYear() + 100);
        this.user = this.authService.getUser();
        this.filteredTags = this.tagSearchControl.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filterTags(value))
            );
        this.route.params
            .subscribe(params => {
                this.id = params['id'];
                if (this.id) {
                    this.getTimeline();
                } else if (this.user?.role?.role_type == 'young_person') {
                    // If no id get users timeline
                    this.contentService.viewMyTimeline().subscribe(r => {
                        this.id = r.id;
                        this.getTimeline();
                    }, e => {
                        this.snackbarService.openSnackBar(e, 'error')
                        this.loading = false;
                    })
                } else {
                    // adults do not have a timeline
                    this.router.navigate(['/people']);
                }

            }, error => {
                console.error(error);
            });

        this.contentService.getAllTags().subscribe(r => {
            this.tags = r.results;
        }, e => {
            console.error(e)
        })


    }

    navigate(url: string, id?: number, timelineId?: number): void {
        if (timelineId) {
            this.router.navigate([url, id, timelineId]);
        } else if (id) {
            this.router.navigate([url, id]);

        } else {
            this.router.navigate([url]);
        }
    }

    getTimelinePermissions(): void {


        this.contentService.viewTimelinePermissions(this.timeline?.id || this.id).subscribe(
            r => {
                for (let permission of r) {
                    if (permission.user.id == this.user.id) {
                        this.permission = permission.permission
                    }
                }
                if (this.permission !== 'Add but not View or Comment') {
                    this.contentService.getFavouriteMedia(0, 1, this.timeline?.child.id, 'Image').subscribe(r => {
                        if (r.results[0]?.media.thumbnail) {
                            this.favouritesThumbnail = r.results[0]?.media.thumbnail;
                        } else {
                            this.favouritesThumbnail = 'assets/images/yella.svg';
                        }
                    }, e => {
                        this.favouritesThumbnail = 'assets/images/yella.svg';
                        console.error(e)
                    });
                }
                if ((!this.permission || this.permission == 'No permission') && this.user?.role?.role_type !== 'admin') {
                    this.snackbarService.openSnackBar('Sorry you do not have permission to view this timeline', 'error');
                    this.router.navigate(['/noAccess']);
                } else if (this.user?.role?.role_type == 'admin') {
                    this.permission = 'View, Comment and Add';
                }
            }, e => {
                this.snackbarService.openSnackBar(e, 'error');
                this.router.navigate(['/noAccess']);
                console.error(e)
            })
    }

    dateChange(event?: any, endDate?: boolean): void {

        // only trigger filter if the end date has been inputted
        // or if the start date was inputted 2 second ago without the end being input
        // To reduce api calls

        if (event.value) {
            if (endDate) {
                let date: Date = new Date(event.value)
                this.endDate = date.toLocaleDateString('en-CA').slice(0, 10);
                clearTimeout(this.dateTimer);
                if (this.endDate && this.startDate) {
                    this.getTimeline()
                }
            } else {
                let date: Date = new Date(event.value)
                this.startDate = date.toLocaleDateString('en-CA').slice(0, 10);
                const that: this = this;
                this.dateTimer = setTimeout((): void => {
                    if (this.endDate && this.startDate) {
                        that.getTimeline();
                    }
                }, 2000);
            }
        }
    }

    clearDate(): void {
        this.endDate = null;
        this.startDate = null;
        this.getTimeline();
        this.startDateField.setValue(null);
        this.endDateField.setValue(null);
    }

    getTimeline(order?: any): void {
        if (order) {
            this.order = order;
        }
        this.loading = true;
        let tags: string[] = []
        for (let tag of this.tags) {
            if (this.selectedTags.includes(tag.id)) {
                tags.push(tag.name)
            }
        }
        this.contentService.viewTimelineList(this.id, this.order, this.startDate, this.endDate, this.searchTerm, tags).subscribe(r => {
            this.timeline = r;
            if (this.timeline.child.id == this.user.id) {
                this.timeline.title = 'My Timeline';
            }
            this.loading = false;
            this.years = r.years;
            this.getTimelinePermissions()
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
            this.loading = false;
        })
    }


    selectTag(event: MatAutocompleteSelectedEvent): void {
        const tag = event as any;
        this.selectedTags.push(tag);
        if (this.tagInput && this.tagInput.nativeElement) {
            this.tagInput.nativeElement.value = '';
        }
        this.getTimeline();
    }

    removeTag(tag: any): void {
        const i: number = this.selectedTags.findIndex((x: any): boolean => x === tag);
        if (i != null && i >= 0) {
            this.selectedTags.splice(i, 1);
        }
        this.getTimeline();
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        const i = otherArray.findIndex((x: any): boolean => x === item);
        return (i != null && i >= 0);
    }

    keyup(): void {
        clearTimeout(this.typingTimer);
        const that: this = this;
        this.typingTimer = setTimeout((): void => {
            that.loading = true;
            that.getTimeline();
        }, 1000);
    }


    clearSearch(): void {
        this.searchTerm = '';
        this.getTimeline();
    }

    keydown(): void {
        clearTimeout(this.typingTimer);
    }

    newMemory(): void {
        if (this.dialog.openDialogs.length == 0) {
            window.scrollTo(0, 0);
            // stops google places location pop up from being in the wrong place
            const dialogRef: MatDialogRef<MemoryFormComponent> = this.dialog.open(MemoryFormComponent, {
                data: {timeline: this.timeline.id},
                panelClass: 'fullWidthWindow',
            });
            dialogRef.afterClosed().subscribe((): void => {
                this.getTimeline();
            });
        }
    }

    exportTimeline(): void {
        this.contentService.requestExport(this.id).subscribe(r => {
            this.snackbarService.openSnackBar(r.message, 'success')
        }, e => {
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    private _filterTags(value: string): string[] {
        let filterValue: string = '';
        if (typeof value == 'string') {
            filterValue = value.toLowerCase();
        }
        let results: any[] = this.tags.filter((option: any) => option.name.toLowerCase().includes(filterValue));
        results = results.filter((item: any, index: any): boolean => {
            return (results.indexOf(item) == index);
        });
        return results;
    }
}
