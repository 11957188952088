<mat-sidenav-container class="minFullHeight ">
    <mat-sidenav (closed)="sideNav = false" [mode]="mode" [opened]="sideNav">
        <mat-nav-list class="p-0">
            <!--Will be child or adult or admin in prod-->
            <mat-list-item *ngIf="this.user?.role?.role_type == 'young_person'"
                           [routerLinkActive]="['mat-background-primary', 'primaryContrast']"
                           [routerLink]="['/timeline']"
                           class="align-items-center" disableRipple>
                <mat-icon class="pr-10" matListItemIcon>
                    <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
                </mat-icon>
                <div matListItemTitle>Timeline</div>
            </mat-list-item>

            <mat-list-item *ngIf="this.user?.role?.role_type === 'admin'" [routerLinkActive]="['mat-background-primary', 'primaryContrast']"
                           [routerLink]="['/users']"
                           class="align-items-center" disableRipple>
                <mat-icon class="pr-10" matListItemIcon>security</mat-icon>
                <div matListItemTitle>Return to Admin Dashboard</div>
            </mat-list-item>
            <mat-list-item (click)="openNotificationNav()" (keyup.enter)="openNotificationNav()"
                           [matBadgeHidden]="!notificationsCount"
                           [matBadge]="notificationsCount"
                           [routerLinkActive]="['mat-background-primary', 'primaryContrast']"
                           class="align-items-center notificationBadge" disableRipple
                           matBadgeColor="warn" matBadgePosition="before" matBadgeSize="small">
                <mat-icon class="pr-10" matListItemIcon>notifications</mat-icon>
                <div matListItemTitle>Notifications</div>
            </mat-list-item>
            <mat-list-item [routerLinkActive]="['mat-background-primary', 'primaryContrast']" [routerLink]="['/people']"
                           class="align-items-center" disableRipple>
                <mat-icon class="pr-10 customIcon" matListItemIcon
                          svgIcon="boxIcon"></mat-icon>
                <div matListItemTitle>Memory Boxes</div>
            </mat-list-item>

            <mat-list-item *ngIf="this.user?.role?.role_type == 'young_person'"
                           [routerLinkActive]="['mat-background-primary', 'primaryContrast']"
                           [routerLink]="['/favourites']"
                           class="align-items-center" disableRipple>
                <mat-icon class="pr-10 customIcon" matListItemIcon>favorite</mat-icon>
                <div matListItemTitle>Favourites</div>
            </mat-list-item>

            <mat-expansion-panel class="dropdownMenuItem mat-elevation-z0" hideToggle>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon class="pr-10" matListItemIcon>school</mat-icon>
                        Training Centre
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-list-item [routerLink]="['/faqs']" class="align-items-center" disableRipple>
                    <div matListItemTitle>FAQs</div>
                </mat-list-item>
                <!--                <mat-list-item [routerLink]="['/resources']" class="align-items-center" disableRipple>-->
                <!--                    <div matListItemTitle>Resources</div>-->
                <!--                </mat-list-item>-->
                <mat-list-item [routerLink]="['/videoGuides']" class="align-items-center" disableRipple>
                    <div matListItemTitle>Video Guides</div>
                </mat-list-item>
                <mat-list-item [routerLink]="['/writtenGuides']" class="align-items-center" disableRipple>
                    <div matListItemTitle>Written Guides</div>
                </mat-list-item>
            </mat-expansion-panel>
            <mat-list-item [routerLinkActive]="['mat-background-primary', 'primaryContrast']"
                           [routerLink]="['/settings']"
                           class="align-items-center" disableRipple>
                <mat-icon class="pr-10" matListItemIcon>settings</mat-icon>
                <div matListItemTitle>Settings</div>
            </mat-list-item>
            <mat-list-item (click)="manageAccess()" (keyup.enter)="manageAccess()"
                           *ngIf="this.user?.role?.role_type == 'young_person'"
                           class="align-items-center" disableRipple>
                <mat-icon class="pr-10" matListItemIcon>
                    <mat-icon>lock</mat-icon>
                </mat-icon>
                <div matListItemTitle>Manage Access</div>
            </mat-list-item>
            <mat-list-item (click)="logout()" (keyup.enter)="logout()" class="align-items-center" disableRipple>
                <mat-icon class="pr-10" matListItemIcon>exit_to_app</mat-icon>
                <div matListItemTitle>Log-out</div>
            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav (closed)="notificationNavOpened = false;" [opened]="notificationNavOpened" class="alertMenu p-10"
                 mode="over" position="end">
        <div class="flex-row d-flex flex-space-between">
            <h3>Notifications</h3>
            <button (click)="notificationNavOpened = false;" (keyup.enter)="notificationNavOpened = false;"
                    mat-icon-button tabindex="-1">
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <app-notification-sidebar (getCount)="this.getNotificationCount()"
                                  [getNotifications]="this.notificationNavOpened">
        </app-notification-sidebar>

    </mat-sidenav>
    <mat-sidenav-content autosize>
        <div *ngIf="hijacking" class="hijackBar">
            <p>You are viewing the VMB platform as {{ user?.full_name }} </p>
            <button (click)="logout()" (keyup.enter)="logout()" aria-label="Log Out" mat-icon-button
                    matTooltip="Log Out">
                <mat-icon>exit_to_app</mat-icon>
            </button>
        </div>
        <mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
            <!--Will be child or adult or admin in prod-->
            <div class="flex-row align-items-center">
                <button [routerLink]="[this.user?.role?.role_type == 'young_person' ? '/timeline' : '/people']"
                        aria-label="Home" id="home"
                        mat-icon-button matTooltip="Home">
                    <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
                </button>

                <p class="m-0 greeting">{{ user?.first_name | greeting }}</p>
            </div>
            <div *ngIf="!isMobile">
                <button *ngIf="this.user?.role?.role_type === 'admin'" [routerLink]="['/users']"
                        aria-label="Return to Admin Dashboard" mat-icon-button
                        matTooltip="Return to Admin Dashboard">
                    <mat-icon [routerLinkActive]="'mat-accent'">security</mat-icon>
                </button>
                <button (click)="openNotificationNav()" (keyup.enter)="openNotificationNav()"
                        [matBadgeHidden]="!notificationsCount" [matBadge]="notificationsCount"
                        aria-label="Notifications" class="notificationBadge"
                        mat-icon-button matBadgeColor="warn"
                        matBadgePosition="after" matBadgeSize="small" matTooltip="Notifications">
                    <mat-icon [routerLinkActive]="'mat-accent'">notifications</mat-icon>
                </button>
                <button [routerLink]="['/people']" aria-label="View People"
                        mat-icon-button
                        matTooltip="Memory Boxes">
                    <mat-icon [routerLinkActive]="'mat-accent'" class="customIcon" svgIcon="boxIcon"></mat-icon>
                </button>
                <button *ngIf="this.user?.role?.role_type == 'young_person'" [routerLink]="['/favourites']"
                        aria-label="Favourites"
                        mat-icon-button
                        matTooltip="My favourite memories">
                    <mat-icon [routerLinkActive]="'mat-accent'">favorite</mat-icon>
                </button>
                <button *ngIf="this.user?.role?.role_type !== 'admin'" [matMenuTriggerFor]="training"
                        aria-label="Training Centre"
                        mat-icon-button
                        matTooltip="Training Centre">
                    <mat-icon [routerLinkActive]="'mat-accent'">school</mat-icon>
                </button>
                <mat-menu #training="matMenu">
                    <button [routerLink]="['/faqs']" mat-menu-item>FAQs</button>
                    <!--                    <button mat-menu-item [routerLink]="['/resources']">Resources</button>-->
                    <button [routerLink]="['/videoGuides']" mat-menu-item>Video Guides</button>
                    <button [routerLink]="['/writtenGuides']" mat-menu-item>Written Guides</button>
                </mat-menu>

                <button [routerLink]="['/settings']" aria-label="Settings"
                        mat-icon-button
                        matTooltip="Settings">
                    <mat-icon [routerLinkActive]="'mat-accent'">settings</mat-icon>
                </button>
                <button (click)="manageAccess()" (keyup.enter)="manageAccess()"
                        *ngIf="this.user?.role?.role_type == 'young_person'"
                        aria-label="Manage Access"
                        mat-icon-button matTooltip="Manage Access">
                    <mat-icon>lock</mat-icon>
                </button>
                <button (click)="logout()" (keyup.enter)="logout()" aria-label="Log Out" mat-icon-button
                        matTooltip="Log Out">
                    <mat-icon>exit_to_app</mat-icon>
                </button>
            </div>
            <button (click)="sideNav = !sideNav" (keyup.enter)="sideNav = !sideNav" *ngIf="isMobile"
                    aria-label="Open Side Menu" mat-icon-button>
                <mat-icon>menu</mat-icon>
            </button>
        </mat-toolbar>
        <div class="minFullHeight">
            <router-outlet #outlet="outlet"></router-outlet>
        </div>
        <app-footer></app-footer>
    </mat-sidenav-content>
</mat-sidenav-container>
