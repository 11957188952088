<main *ngIf="memory" class="mediumContainer">
    <div class="d-flex flex-row">
        <button [routerLink]="['/timeline', this.timelineId]" aria-label="Back to timeline" mat-icon-button
                matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div *ngIf="!memory?.duplication_in_progress" class="d-flex flex-row flex-space-between pb-10 mobile-col">
        <div class="flex-col">
            <h1 class="my-0">{{ memory.title }}</h1>
        </div>
        <div *ngIf="permission == 'View, Comment and Add' || permission == 'Add but not View or Comment'"
             class="flex-row gap10 justify-content-end">
            <ng-container *ngIf="reOrder">
                <button (click)="saveReorder()" (keyup.enter)="saveReorder()" [disabled]="orderLoading"
                        aria-label="Save Memory Order"
                        class="mat-elevation-z2"
                        color="primary"
                        mat-raised-button matTooltip="Save Memory Order">
                    Save Order
                    <mat-icon *ngIf="orderLoading" matSuffix>
                        <mat-spinner color="primary"></mat-spinner>
                    </mat-icon>
                </button>
                <button (click)="refresh()" (keyup.enter)="refresh()" [disabled]="orderLoading"
                        aria-label="Cancel Re-order"
                        class="mat-elevation-z2"
                        color="warn"
                        mat-mini-fab matTooltip="Cancel Re-order">
                    <mat-icon>cancel</mat-icon>
                </button>
            </ng-container>
            <ng-container *ngIf="!reOrder">

                <button (click)="newMedia()" (keyup.enter)="newMedia()" aria-label="Add Memory" class="mat-elevation-z2"
                        color="primary"
                        mat-mini-fab matTooltip="Add Memory">
                    <mat-icon>
                        add_photo_alternate
                    </mat-icon>
                </button>
                <button (click)="recordMedia()" (keyup.enter)="recordMedia()" aria-label="Record New Memory"
                        class="mat-elevation-z2"
                        color="primary" mat-mini-fab matTooltip="Record New Memory">
                    <mat-icon>
                        perm_camera_mic
                    </mat-icon>
                </button>
                <button (click)="duplicateAlbum()" (keyup.enter)="duplicateAlbum()"
                        *ngIf="(this.user?.role?.role_type == 'adult' || this.user?.role?.role_type == 'admin') && user.role.role_type !== 'young_person'"
                        aria-label="Duplicate Album" class="mat-elevation-z2" color="primary"
                        mat-mini-fab matTooltip="Duplicate Album">
                    <mat-icon>
                        filter_none
                    </mat-icon>
                </button>
                <button (click)="initReOrder()" (keyup.enter)="initReOrder()"
                        aria-label="Re-Order Memories"
                        class="mat-elevation-z2"
                        color="primary"
                        mat-mini-fab matTooltip="Re-order Memories">
                    <mat-icon>reorder</mat-icon>
                </button>
            </ng-container>
        </div>
    </div>
    <div *ngIf="!memory.duplication_in_progress" class="memoryContainer">
        <div>
            <div>
                <div *ngIf="loading" class="flex-row justify-content-center w-100">
                    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                </div>
                <div *ngIf="!loading" [cdkDropListOrientation]="'mixed'" cdkDropList class="picsContainer draglist"
                     (cdkDropListDropped)="changeOrder($event)">
                    <mat-card *ngFor="let file of memory.media; let i = index" cdkDrag
                              class="dragbox clickable imageCard">
                        <div *cdkDragPlaceholder class="dragPlaceholder"></div>

                        <button *ngIf="reOrder" cdkDragHandle class="dragIcon" color="primary"
                                mat-raised-button
                                matTooltip="Drag To Change Order">
                            <mat-icon>drag_indicator</mat-icon>
                        </button>

                        <app-media-display (viewImage)="viewImage(i)" [file]="file" [small]="true"
                                           aria-label="View Media"></app-media-display>

                        <mat-card-header (click)="viewImage(i)" (keyup.enter)="viewImage(i)" class="pb-16" tabindex="2">
                            <mat-card-title>{{ file.title | ellipsis:40 }}</mat-card-title>
                            <mat-card-subtitle *ngIf="file.description">{{ file.description  | ellipsis:65 }}
                            </mat-card-subtitle>
                        </mat-card-header>
                    </mat-card>
                </div>
                <mat-paginator (page)="handlePageEvent($event)" [hidePageSize]='true' [length]="count"
                               [pageSize]="limit"
                               aria-label="Select page"></mat-paginator>
            </div>
        </div>
        <div class="d-flex flex-col gap-15">
            <mat-card>
                <mat-card-header class="flex-space-between">
                    <mat-card-title class="f-20">Album Details</mat-card-title>
                </mat-card-header>
                <mat-card-content class="mt-10">
                    <ng-container *ngIf="memory.description">
                        <b>Description:</b>
                        <p class="mt-5">{{ memory.description }}</p>
                    </ng-container>
                    <ng-container *ngIf="memory.attendees">
                        <b>Who was there:</b>
                        <p class="mt-5">{{ memory.attendees }}</p>
                    </ng-container>
                    <ng-container *ngIf="memory.date">
                        <b>When it happened:</b>
                        <p class="mt-5">{{ memory.date| date:'mediumDate' }}</p>
                    </ng-container>
                    <div *ngIf="memory.creator" class="flex-col mb-10">
                        <b class="mb-10">Created By:</b>
                        <mat-chip (click)="viewProfile(memory.creator?.id)"
                                  (keyup.enter)="viewProfile(memory.creator?.id)"
                                  [attr.aria-label]="user.role.can_access_profiles ? [memory.creator.full_name + ': Click to view profile'] : []"
                                  [class.clickable]="user.role.can_access_profiles"
                                  class="smChip w-content">
                            <img *ngIf="memory.creator.photo"
                                 [alt]="memory.creator.full_name + ': Click to view profile'"
                                 [src]="memory.creator.thumbnail|| memory.creator.photo" fetchPriority="low"
                                 loading="lazy"
                                 matChipAvatar/>
                            {{ memory.creator.full_name }}
                        </mat-chip>
                    </div>
                    <ng-container *ngIf="memory?.tags?.length > 0">
                        <b class="mb-0">Tags:</b>
                        <mat-chip-listbox>
                            <mat-chip *ngFor="let tag of memory.tags" class="smChip" color="primary">
                                {{ tag.name }}
                            </mat-chip>
                        </mat-chip-listbox>
                    </ng-container>
                </mat-card-content>
                <mat-card-actions
                        *ngIf="permission == 'View, Comment and Add' || permission == 'Add but not View or Comment'"
                        align="end">
                    <button (click)="editDetails()" (keyup.enter)="editDetails()"
                            color="primary" mat-raised-button>Edit
                    </button>
                </mat-card-actions>
            </mat-card>
            <mat-card *ngIf="googleLoaded && memory.latitude" class="overflow-hidden">
                <mat-card-header (click)="locationExpanded = !locationExpanded"
                                 (keyup.enter)="locationExpanded = !locationExpanded"
                                 [attr.aria-label]="!locationExpanded ? 'Expand Location Section' : 'Hide Location Section'"
                                 class="mb-10 flex-space-between clickable"
                                 role="button">
                    <mat-card-title>Location</mat-card-title>
                    <mat-icon *ngIf="!locationExpanded">
                        keyboard_arrow_down
                    </mat-icon>
                    <mat-icon *ngIf="locationExpanded">
                        keyboard_arrow_up
                    </mat-icon>
                </mat-card-header>
                <mat-accordion>
                    <mat-expansion-panel [class.mat-expansion-panel]="false" [expanded]="locationExpanded" hideToggle>
                        <google-map [attr.aria-label]="'Location of memory: ' + memory.address "
                                    [center]="{lat: memory.latitude, lng: memory.longitude}"
                                    [options]="{ mapTypeControl: false, scaleControl: false, draggable: false, scrollwheel: false,  disableDefaultUI: true}"
                                    [zoom]="14"
                                    height="300px" mat-card-image
                                    tabindex="-1"/>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card>
            <mat-card *ngIf="permission == 'View and Comment' || permission == 'View, Comment and Add'">
                <mat-card-header (click)="commentsExpanded = !commentsExpanded"
                                 (keyup.enter)="commentsExpanded = !commentsExpanded"
                                 [attr.aria-label]="!commentsExpanded ? 'Expand Comments Section' : 'Hide Comments Section'"
                                 class="mb-10 clickable flex-space-between"
                                 role="button">
                    <mat-card-title>Comments</mat-card-title>
                    <mat-icon *ngIf="!commentsExpanded">
                        keyboard_arrow_down
                    </mat-icon>
                    <mat-icon *ngIf="commentsExpanded">
                        keyboard_arrow_up
                    </mat-icon>
                </mat-card-header>
                <mat-accordion>
                    <mat-expansion-panel [class.mat-expansion-panel]="false" [expanded]="commentsExpanded" hideToggle>
                        <app-comments (comment)="sendComment($event)" [comments]="this.comments" [mediaComment]="false"
                                      [reLoading]="commentsLoading"></app-comments>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card>
        </div>
    </div>
</main>

<div *ngIf="!memory || memory?.duplication_in_progress" class="centerLoading flex-col text-center">

    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>

    <p *ngIf="memory?.duplication_in_progress" class="errorMsg">Memories for this album are being duplicated at the
        moment,<br> please
        check back soon to see the full album</p>
</div>
