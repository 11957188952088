<main class="mediumContainer">
    <div class="d-flex flex-row">
        <button (click)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div [class.d-flex]="!media" class="profileContainer">
        <div *ngIf="media">
            <mat-card aria-label="View Picture" class="clickable imageCard">
                <app-media-display (viewImage)="viewImage()" [file]="media" [small]="true"></app-media-display>
                <mat-card-header (click)="viewImage()" class="pb-16">
                    <mat-card-title>{{ media.title }}</mat-card-title>
                    <mat-card-subtitle *ngIf="media.description">{{ media.description }}
                    </mat-card-subtitle>
                </mat-card-header>
            </mat-card>
        </div>
        <div *ngIf="flag" class="d-flex flex-col gap-15 flex-grow">
            <mat-card>
                <mat-card-header class="flex-space-between">
                    <mat-card-title>Flagged Media</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <br>
                    <p><b>Title</b>: {{ flag.title }}</p>
                    <p><b>Category</b>: {{ flag.category }}</p>
                    <p *ngIf="flag.text"><b>Description</b>: {{ flag.text }}</p>
                    <p><b>Date of Flag</b>: {{ flag.created_date | date }}</p>
                    <p><b>Reported By</b>: <a [routerLink]="['/user', flag.creator.id]">{{ flag.creator.full_name }}</a>
                    </p>
                    <p *ngIf="flag.timeline"><b>Memory Box</b>: <a
                            [routerLink]="['/timeline', flag.timeline.id]">{{ flag.timeline.title }}</a></p>
                    <p *ngIf="flag.content?.memory?.id"><b>Album</b>: <a
                            [routerLink]="['/memory', flag.content?.memory?.id]">{{ flag.content?.memory.title }}</a>
                    </p>

                </mat-card-content>
            </mat-card>
            <mat-card>
                <mat-card-header class="flex-space-between">
                    <mat-card-title>Resolution</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div class="formGrid mt-10 resolution">
                        <mat-form-field appearance="outline" class="w-100" color="primary">
                            <mat-label>Status:</mat-label>
                            <select [value]="flag.resolution_status" disabled="true" matNativeControl>
                                <option>Resolved</option>
                                <option>Pending</option>
                                <option>Actioned</option>
                            </select>
                        </mat-form-field>
                        <mat-form-field *ngIf="flag.processed_by?.id && flag.resolution_status !== 'Pending'"
                                        appearance="outline" class="w-100"
                                        color="primary">
                            <mat-label>Resolved By:</mat-label>
                            <input [disabled]="true" [value]="flag.processed_by.full_name"
                                   matInput type="text">
                        </mat-form-field>
                        <mat-form-field *ngIf="flag.resolution_status =='Pending' || resolution" appearance="outline"
                                        class="w-100">
                            <mat-label>Explanation of resolution:</mat-label>
                            <textarea [(ngModel)]="resolution" [disabled]="flag.resolution_status !='Pending'" matInput
                                      maxlength="400"></textarea>
                        </mat-form-field>
                        <mat-form-field *ngIf="flag.resolution_status_date && flag.resolution_status !== 'Pending'"
                                        appearance="outline" class="w-100">
                            <mat-label>Date of Resolution</mat-label>
                            <input [disabled]="true" [value]="flag.resolution_status_date | date : 'yyyy-MM-dd'"
                                   matInput type="date">
                        </mat-form-field>
                    </div>
                </mat-card-content>
                <mat-card-actions class="flex-row flex-space-around">
                    <button (click)="hide()" *ngIf="media && !this.media.hidden" color="warn" mat-raised-button>Hide
                    </button>
                    <button (click)="unhide()" *ngIf="media && this.media.hidden" color="primary" mat-raised-button>
                        Un-hide
                    </button>
                    <button (click)="delete()" *ngIf="media" color="warn" mat-raised-button>Delete</button>
                    <button (click)="moveMedia()" *ngIf="media" color="primary" mat-raised-button>Move</button>
                    <button (click)="updateUserFeedback()" color="accent" mat-raised-button>
                        Mark
                        <ng-container *ngIf="flag.resolution_status == 'Pending';else unresolved">Resolved
                        </ng-container>
                    </button>
                    <ng-template #unresolved>Unresolved</ng-template>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>
</main>
