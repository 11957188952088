<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Dashboard
        </h3>
    </div>
    <div class="largeContainer">
        <div class="statsGrid">
            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>Invites</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="!adminOverview" class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-container *ngIf="adminOverview">
                        <apx-chart *ngIf="(adminOverview.invites_accepted && adminOverview.invites_accepted > 0 )||
                                     (adminOverview.invites_pending && adminOverview.invites_pending > 0 )||
                                     (adminOverview.invites_expired && adminOverview.invites_expired > 0 )"
                                   [chart]="{ width: '100%', type: 'donut'}"
                                   [colors]="['#FFAC1C','#D2042D']"
                                   [labels]="[ 'Pending', 'Expired']"
                                   [legend]="{show: false}"
                                   [series]="[adminOverview.invites_pending||0, adminOverview.invites_expired||0]"
                                   [tooltip]="{enabled: false}"
                                   class="noPointerEvents"
                        ></apx-chart>
                        <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                            <div *ngIf="adminOverview.invites_pending != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <button [routerLink]="['/invitees']" mat-button>Pending:</button>
                                <div class="statCircle warning">{{ adminOverview.invites_pending }}</div>
                            </div>
                            <div *ngIf="adminOverview.invites_expired != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <button [routerLink]="['/invitees']" mat-button>Expired:</button>
                                <div class="statCircle danger">{{ adminOverview.invites_expired }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <button [routerLink]="['/invitees']" mat-button>Total:</button>
                                <div class="statCircle black">{{ adminOverview.invites_pending || 0 + adminOverview.invites_expired || 0 }}</div>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>Flagged Media</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <div *ngIf="!adminOverview && !reportingOverview?.error"
                         class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-container *ngIf="adminOverview && adminOverview.flagged">
                        <apx-chart *ngIf="(adminOverview.flagged.actioned && adminOverview.flagged.actioned > 0 )||
                        (adminOverview.flagged.unactioned  && adminOverview.flagged.unactioned > 0 )"
                                   [chart]="{ width: '100%', type: 'donut'}"
                                   [colors]="['#67B688','#D2042D',]"
                                   [labels]="['Actioned', 'Unactioned',]"
                                   [legend]="{show: false}"
                                   [series]="[adminOverview.flagged.actioned||0, adminOverview.flagged.unactioned||0]"
                                   [tooltip]="{enabled: false}"
                                   class="noPointerEvents"
                        ></apx-chart>
                        <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                            <div *ngIf="adminOverview.flagged.actioned != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <button [routerLink]="['/resolved']" mat-button>Actioned:</button>
                                <div class="statCircle success">{{ adminOverview.flagged.actioned | number:'1.0-2' }}</div>
                            </div>
                            <div *ngIf="adminOverview.flagged.unactioned != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <button [routerLink]="['/flaggedMedia']" mat-button>Unactioned:</button>
                                <div class="statCircle danger">{{ adminOverview.flagged.unactioned| number:'1.0-2' }}</div>
                            </div>
                            <div *ngIf="adminOverview.flagged.total != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <button [routerLink]="['/flaggedMedia']" mat-button>Total:</button>
                                <div class="statCircle black">{{ adminOverview.flagged.total | number:'1.0-2' }}</div>
                            </div>
                            <div *ngIf="adminOverview.flagged.average_response_time != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10"> Average
                                Response Time:
                                <div class="statCircle black">{{ adminOverview.flagged.average_response_time | number:'1.0-2' }}
                                    Days
                                </div>
                            </div>
                        </div>

                    </ng-container>
                </mat-card-content>
            </mat-card>

            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>User Statistics</mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-100">
                    <div *ngIf="!reportingOverview && !reportingOverview?.error"
                         class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div *ngIf="reportingOverview" class="flex-col pt-10 flex-space-around h-100 align-items-center">
                        <apx-chart [chart]="{ width: '100%', type: 'bar'}"
                                   [colors]="['#67B688', '#FFAC1C']"
                                   [fill]="{colors:['#67B688', '#FFAC1C']}"
                                   [series]="[{name:'Memory Box', data:[childrenNumber,carerNumber]}]"
                                   [tooltip]="{enabled: false}"
                                   [xaxis]="{categories: ['Children', 'Carers']}"
                                   class="w-100 noPointerEvents"
                        ></apx-chart>
                        <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10">

                                <a href="/users?role=12,13,11" mat-button>Children:</a>
                                <div class="statCircle success">{{ childrenNumber }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <a href="/users?role=6" mat-button>Carers:</a>
                                <div class="statCircle warning">{{ carerNumber }}</div>
                            </div>

                            <div *ngIf="reportingOverview.total_users != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <button [routerLink]="['/users']" mat-button> Total Users:</button>
                                <div class="statCircle black">{{ reportingOverview.total_users| number:'1.0-2' }}</div>
                            </div>
                            <div *ngIf="reportingOverview.total_size != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">
                                Total Memory Box Size:
                                <div class="statCircle black">{{ reportingOverview.total_size| number:'1.0-2' }}GB
                                </div>
                            </div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header>
                    <mat-card-title>Memory Box Statistics</mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-100">
                    <div *ngIf="!reportingOverview && !reportingOverview?.error"
                         class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <div *ngIf="reportingOverview" class="flex-col pt-10 flex-space-around h-100 align-items-center">
                        <apx-chart [chart]="{ width: '100%', type: 'bar'}"
                                   [fill]="{colors:['#67B688', '#FFAC1C']}"
                                   [series]="[{name:'Memory Box', data:[reportingOverview.total_memory_boxes||0,reportingOverview.orphan_boxes||0,reportingOverview.total_memory_boxes_with_no_uploads||0 ]}]"
                                   [tooltip]="{enabled: false}"
                                   [xaxis]="{categories: ['Total','No Relationships', 'No Uploads']}"
                                   class="w-100 noPointerEvents"
                        ></apx-chart>
                        <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                            <div *ngIf="reportingOverview.total_users != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">
                                Total Memory Boxes:
                                <div class="statCircle black">{{ reportingOverview.total_memory_boxes| number:'1.0-2' }}</div>
                            </div>
                            <div class="flex-row align-items-center justify-content-center gap10 pb-10">
                                <a [routerLink]="['/reports']" [queryParams]="{report: 'noRelationships'}" mat-button>Unlinked
                                    Memory Boxes:</a>
                                <div class="statCircle danger">{{ reportingOverview.orphan_boxes| number:'1.0-2' }}</div>
                            </div>

                            <div *ngIf="reportingOverview.total_size != null"
                                 class="flex-row align-items-center justify-content-center gap10 pb-10">

                                <a [routerLink]="['/reports']" [queryParams]="{report: 'noMemories'}" mat-button>
                                    Memory Boxes With No Uploads:
                                </a>
                                <div class="statCircle warning">{{ reportingOverview.total_memory_boxes_with_no_uploads| number:'1.0-2' }}
                                </div>
                            </div>

                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</main>
