<main>
    <div class="largeContainer">
        <div class="d-flex flex-row flex-space-between">
            <button (click)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <div><h3 class="my-10">
                Bulk Invite Jobs
            </h3></div>
            <div></div>
        </div>
        <div class="flex-col gap-25">
            <mat-card>
                <mat-card-content>
                    <table [dataSource]="dataSource" mat-table class="responsiveTable w-100"
                           *ngIf="this.dataSource.data.length > 0">
                        <ng-container matColumnDef="created">
                            <th *matHeaderCellDef mat-header-cell>Created At</th>
                            <td *matCellDef="let row" mat-cell><b class="d-none columnTitle">
                                Status:</b> {{ row.created_date | date }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="status">
                            <th *matHeaderCellDef mat-header-cell>Status</th>
                            <td *matCellDef="let row" mat-cell><b class="d-none columnTitle">
                                Status:</b> {{ row.status|removeSnakecase  }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="view">
                            <th *matHeaderCellDef mat-header-cell>View Job</th>
                            <td *matCellDef="let row" mat-cell>
                                <a mat-raised-button color="primary" class="text-center"
                                   [routerLink]="['/bulkInvite', row.id]">
                                    View Job
                                </a>
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>

                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </mat-card-content>
                <mat-card-footer *ngIf="loading">
                    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</main>
