<main class="loginBannerBg" role="main">
    <div class="container d-flex justify-content-center">
        <div class="d-flex flex-column">
            <div>
                <mat-card class="mat-elevation-z0">
                    <template [ngTemplateOutlet]="logos"></template>

                    <mat-card-content>
                        <form [formGroup]="passwordForm">
                            <div class="flex-col">
                                <mat-form-field appearance="outline" class="w-100" color="primary">
                                    <mat-label>New Password</mat-label>
                                    <input #password formControlName="password" matInput autocomplete="new-password"
                                           type="password">
                                    <mat-error (click)="password.focus()"
                                               *ngIf="passwordForm.controls['password'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field appearance="outline" class="w-100" color="primary">
                                    <mat-label>Confirm New Password</mat-label>
                                    <input #confirmPassword formControlName="confirmPassword" autocomplete="new-password"
                                           matInput type="password">
                                    <mat-error (click)="confirmPassword.focus()"
                                               *ngIf="passwordForm.controls['confirmPassword'].hasError('required')">
                                        This field is required
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <app-password-validator [matching]="password.value != confirmPassword.value"
                                                    [password]="password.value"></app-password-validator>
                        </form>
                    </mat-card-content>
                    <mat-card-actions class="text-center">
                        <button (click)="resetPassword()" [disabled]="passwordForm.invalid || loading"
                                class="mx-auto my-10"
                                color="primary" mat-flat-button type="submit">
                            Update Password
                            <mat-icon *ngIf="loading">
                                <mat-spinner color="primary" width="40px"></mat-spinner>
                            </mat-icon>
                        </button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>

<ng-template #logos>
    <div class="col-row logosGrid">
        <img alt="Virtual Memory Box Logo" class="my-10 logo" src="assets/images/vmb.png">
        <!--        <img *ngIf="councilLogo" [alt]=" + ' Logo'" [src]="councilLogo" class="my-10 logo">-->
    </div>
</ng-template>
