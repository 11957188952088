import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-edit-name',
    templateUrl: './edit-name.component.html',
    styleUrl: './edit-name.component.scss'
})
export class EditNameComponent {
    editNameForm: FormGroup;
    loading: boolean = false;


    constructor(@Inject(MAT_DIALOG_DATA) protected data: any,
                protected dialogRef: MatDialogRef<EditNameComponent>,
                public dialog: MatDialog, private formBuilder: FormBuilder) {

        this.editNameForm = this.formBuilder.group({
            name: [''],
        });

        if (data) {
            this.editNameForm.patchValue({
                name: data.name,
            });
        }

    }
}
