<main>
    <div class="mediumContainer">
        <div class="d-flex flex-row flex-space-between">
            <button (click)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <div class="d-flex flex-row justify-content-center">
                <h3 class="my-10">
                    {{ cohort?.cohort?.name || 'Cohort' }}
                </h3>
            </div>
            <div></div>
        </div>
    </div>
    <div class="largeContainer">
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div *ngIf="this.cohort" class="flex-row align-items-end mb-10 gap10 justify-content-end align-items-center">
            <button (click)="editCohortModal()"
                    aria-label="Edit Cohort Name" color="primary"
                    mat-raised-button matTooltip="Edit Cohort Name">
                Edit Name
            </button>
            <button (click)="addUsersModal()"
                    aria-label="Add Users to location" color="primary"
                    mat-raised-button matTooltip="Add Users to location">
                Add Users
            </button>
            <ng-container *ngIf="anySelected">
                <button (click)="removeUsers()" color="warn" mat-raised-button>Remove Users From Cohort</button>
            </ng-container>
            <button (click)="delete()"
                    aria-label="Delete Cohort" color="warn"
                    mat-raised-button matTooltip="Delete Cohort">
                Delete Cohort
            </button>
        </div>
        <div class="filtersGrid mb-20">
            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header class="w-100">
                    <mat-card-title>Usage Statistics</mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-100">
                    <div *ngIf="!cohortData" class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-container *ngIf="cohortData">
                        <div class="flex-row pt-10 flex-space-around mobile-col">
                            <apx-chart *ngIf="(cohortData.total_invites_accepted  && cohortData.total_invites_accepted > 0 )||
                                     (cohortData.total_invites_pending && cohortData.total_invites_pending > 0 )||
                                     (cohortData.total_invites_expired && cohortData.total_invites_expired > 0 )"
                                       [chart]="{ width: '100%', type: 'donut'}"
                                       [colors]="['#67B688','#FFAC1C','#D2042D']"
                                       [labels]="['Accepted', 'Pending', 'Expired']"
                                       [legend]="{show: false}"
                                       [series]="[cohortData.total_invites_accepted||0, cohortData.total_invites_pending||0, cohortData.total_invites_expired||0]"
                                       [tooltip]="{enabled: false}"
                            ></apx-chart>
                            <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">
                                <div *ngIf="cohortData.total_invites_accepted != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Accepted:
                                    <div class="statCircle success">{{ cohortData.total_invites_accepted | number:'1.0-2' }}</div>
                                </div>
                                <div *ngIf="cohortData.total_invites_pending != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Pending:
                                    <div class="statCircle warning">{{ cohortData.total_invites_pending | number:'1.0-2' }}</div>
                                </div>
                                <div *ngIf="cohortData.total_invites_expired != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Expired:
                                    <div class="statCircle danger">{{ cohortData.total_invites_expired | number:'1.0-2' }}</div>
                                </div>
                                <div *ngIf="cohortData.percentage_logged_in != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Logged In:
                                    <div class="statCircle black">{{ cohortData.percentage_logged_in | number:'1.0-2' }}
                                        %
                                    </div>
                                </div>
                                <div *ngIf="cohortData.total_users != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Total Users:
                                    <div class="statCircle black">{{ cohortData.total_users | number:'1.0-2' }}</div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>
            <mat-card class="mat-elevation-z6 flex-grow">
                <mat-card-header class="w-100">
                    <mat-card-title>Upload Statistics</mat-card-title>
                </mat-card-header>
                <mat-card-content class="h-100">
                    <div *ngIf="!cohortData" class="flex-row align-items-center justify-content-center">
                        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                    </div>
                    <ng-container *ngIf="cohortData">
                        <div class="flex-row pt-10 flex-space-around mobile-col">
                            <apx-chart
                                    *ngIf="(cohortData.total_uploads.images  && cohortData.total_uploads.images > 0 )||
                                     (cohortData.total_uploads.video && cohortData.total_uploads.video > 0 )||
                                     (cohortData.total_uploads.audio && cohortData.total_uploads.audio > 0 )||
                                     (cohortData.total_uploads.documents && cohortData.total_uploads.documents > 0 )"
                                    [chart]="{ width: '100%', type: 'donut'}"
                                    [colors]="['#67B688','#FFAC1C','#038CC9FF', '#FFD005FF']"
                                    [labels]="['Images', 'Video', 'Audio', 'Documents']"
                                    [legend]="{show: false}"
                                    [series]="[cohortData.total_uploads.images || 0, cohortData.total_uploads.video|| 0, cohortData.total_uploads.audio||0, cohortData.total_uploads.documents || 0]"
                                    [tooltip]="{enabled: false}"
                            ></apx-chart>
                            <div class="d-flex flex-row gap-15 flex-wrap justify-content-center">

                                <div *ngIf="cohortData.total_uploads.images != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Images:
                                    <div class="statCircle success">{{ cohortData.total_uploads.images | number:'1.0-2' }}</div>
                                </div>
                                <div *ngIf="cohortData.total_uploads.video != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Video:
                                    <div class="statCircle warning">{{ cohortData.total_uploads.video | number:'1.0-2' }}</div>
                                </div>
                                <div *ngIf="cohortData.total_uploads.audio != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Audio:
                                    <div class="statCircle primary">{{ cohortData.total_uploads.audio | number:'1.0-2' }}</div>
                                </div>
                                <div *ngIf="cohortData.total_uploads.documents != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Documents:
                                    <div class="statCircle accent">{{ cohortData.total_uploads.documents | number:'1.0-2' }}</div>
                                </div>
                                <div *ngIf="cohortData.total_uploads.total != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Total:
                                    <div class="statCircle black">{{ cohortData.total_uploads.total | number:'1.0-2' }}</div>
                                </div>
                                <div *ngIf="cohortData.percentage_uploaded != null"
                                     class="flex-row align-items-center justify-content-center gap10 pb-10"> Uploaded:
                                    <div class="statCircle black">{{ cohortData.percentage_uploaded | number:'1.0-2' }}
                                        %
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </mat-card-content>
            </mat-card>
        </div>

        <div *ngIf="!loading" class="mat-elevation-z6">
            <table [dataSource]="dataSource" class="responsiveTable" mat-table>
                <ng-container matColumnDef="checkbox">
                    <th *matHeaderCellDef mat-header-cell>
                        <mat-checkbox (ngModelChange)="checkAll()"
                                      [(ngModel)]="allChecked"></mat-checkbox>
                    </th>
                    <td *matCellDef="let user" mat-cell>
                        <mat-checkbox (ngModelChange)="bulkOptions($event, user)"
                                      [(ngModel)]="user.checked"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="img">
                    <th *matHeaderCellDef mat-header-cell></th>
                    <td *matCellDef="let user" [routerLink]="['/user', user.id]" class="clickable"
                        mat-cell>
                        <img [alt]="user.full_name" [src]="user.img||'assets/images/blankProfile.png'"
                             class="smallProfilePicture">
                    </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell> Name</th>
                    <td *matCellDef="let user" [routerLink]="['/user', user.id]" class="clickable"
                        mat-cell><b class="d-none columnTitle">Name:</b> {{ user.full_name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="role">
                    <th *matHeaderCellDef mat-header-cell> Role</th>
                    <td *matCellDef="let user" [routerLink]="['/user', user.id]" class="clickable"
                        mat-cell><b class="d-none columnTitle">Role:</b> {{ user.role?.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="inviteDate">
                    <th *matHeaderCellDef mat-header-cell> Invite Date</th>
                    <td *matCellDef="let user" [routerLink]="['/user', user.id]" class="clickable"
                        mat-cell><b class="d-none columnTitle">Invite Date:</b> {{ user.inviteDate | date }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="inviteStatus">
                    <th *matHeaderCellDef mat-header-cell> Status</th>
                    <td *matCellDef="let user" [routerLink]="['/user', user.id]" class="clickable"
                        mat-cell><b class="d-none columnTitle">Status:</b> {{ user.inviteStatus }}
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="!loading" class="mat-cell text-center" colspan="6">No users in cohort</td>
                </tr>
            </table>
        </div>
    </div>
</main>
