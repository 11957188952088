import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AdminService} from "../../../../_services/admin.service";
import {SnackbarService} from "../../../../_services/snackbar.service";

@Component({
    selector: 'app-new-permission',
    templateUrl: './new-permission.component.html',
    styleUrl: './new-permission.component.scss'
})
export class NewPermissionComponent {
    permissionForm: FormGroup
    userSearchControl: FormControl = new FormControl();
    loadingUsers: boolean = false;
    displayCurrentPermissions: boolean = false;
    filteredUsers: any[] = [];
    currentPermissions: any[] = [];
    timeline_id: any;
    title: string = ''

    constructor(protected dialogRef: MatDialogRef<NewPermissionComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any,
                private formBuilder: FormBuilder,
                private adminService: AdminService,
                private snackBarService: SnackbarService) {

        this.userSearchControl.valueChanges.subscribe(
            (value) => {
                this.loadingUsers = true;

                let filters: any = {
                    order_by: 'full_name',
                    direction: 'asc',
                    deactivated: false
                }
                if (data.filter) {
                    filters[data.filter.name] = data.filter.value;
                }
                this.adminService.getAllUsers(0, 100000, value, filters).subscribe(r => {
                    let users: any[] = [];
                    users = r.results.filter((item: any, index: any): boolean => {
                        return (r.results.indexOf(item) == index);
                    });
                    this.loadingUsers = false;
                    this.filteredUsers = users
                }, e => {
                    this.snackBarService.openSnackBar(e, 'error')
                    this.loadingUsers = false;
                    this.filteredUsers = []
                })
            }
        );

        if (data.title) {
            this.title = data.title
        }
        if (data.timeline_id) {
            this.timeline_id = data.timeline_id
        }
        if (data.currentPermissions) {
            this.currentPermissions = data.currentPermissions
        }
        if (data.displayCurrentPermissions) {
            this.displayCurrentPermissions = data.displayCurrentPermissions
        }
        this.permissionForm = this.formBuilder.group({
            permission: ['', Validators.required],
        });

    }

    createPermission(): void {
        this.adminService.newPermission(this.timeline_id, this.userSearchControl.value.id, this.permissionForm.value.permission).subscribe(r => {
            this.snackBarService.openSnackBar('New relationship created', 'success')
            this.dialogRef.close(r);
        }, e => {
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

    displayFn(user: any): string {
        if (user.full_name && user.full_name != ' ') {
            return user.full_name + ' (' + user.username + ')'
        } else {
            return user.username || user.email
        }
    }

    checkIfInArray(item: any, otherArray: any): boolean {
        let i
        if (otherArray) {
            i = otherArray.findIndex((x: any): boolean => x.user.id === item.id);
        }
        return (i != null && i >= 0);
    }

}
