<ng-container *ngIf="file">
    <div *ngIf="(file.file_type == 'image' || file.image) && !small"
         class="text-center w-100 d-flex justify-content-center">
        <div class="w-content d-flex">
            <img [alt]="file.title" [src]="file.image" class="imageControl croppableMedia" fetchPriority="high"
                 loading="eager">
            <button (click)="this.initiateCrop.emit(file)" (keyup.enter)="this.initiateCrop.emit(file)"
                    *ngIf="this.hasFileType(file.image, this.croppableImageTypes) && this.croppable"
                    class="floatBtn" mat-mini-fab
                    matTooltip="Crop Image">
                <mat-icon>crop</mat-icon>
            </button>
        </div>
    </div>
    <ng-container
            *ngIf="((file.file_type == 'image' || file.image) && small) || (this.browser == 'safari' && (file.file_type == 'document' || file.document)) ">
        <img (click)="this.viewImage.emit()" (contextmenu)="false" (keyup.enter)="this.viewImage.emit()"
             [alt]="file.title + 'image'"
             [class.smallMedia]="small" [src]="file.thumbnail|| file.image"
             class="w-100 bg-primary" fetchPriority="low">
    </ng-container>

    <div *ngIf="(file.audio && file.audio.length > 0)" [class.smallMedia]="small" class="audioControl">
        <app-audio-player (loading)="this.loading = $event"
                          (pauseSlideShow)="this.checkIfSmallBeforePause()" (viewImage)="mediaClick($event)" [file]="file.audio"
                          [mediaId]="file.id"
                          [small]="small"></app-audio-player>
    </div>
    <ng-container *ngIf="(file.video && file.video.length > 0)">
        <video #video (click)="mediaClick($event)" (contextmenu)="false"
               (keyup.enter)="mediaClick($event)" (play)="this.checkUrl(file.video, 'video',  video)"
               [class.smallMedia]="small" [poster]="file.thumbnail || 'assets/images/video.png'"
               [preload]="small ? 'none' : 'auto'"
               [src]="file.video"
               [tabIndex]="small ? -1: -1" class="videoControl bg-primary"
               controls controlsList="nodownload noplaybackrate">
            Your browser does not support the video tag.
        </video>
    </ng-container>
    <ng-container *ngIf="(file.file_type == 'document' || file.document) && (!small || this.browser !== 'safari')">
        <div (click)="this.viewImage.emit()" (keyup.enter)="this.viewImage.emit()" *ngIf="small && this.browser !== 'safari'"
             class="coverObject">
        </div>
        <object (contextmenu)="false" *ngIf="correctDocumentFileType(file.document) && file.safeDocument" [attr.aria-label]="file.title"
                [class.smallMedia]="small"
                [data]="file.safeDocument" [tabIndex]="small ? -1: -1"
                class="documentControl" height="100%" id="document" type="application/pdf"
                width="100%">

        </object>

        <img *ngIf="!correctDocumentFileType(file.document)" [alt]="file.title"
             class="imageControl bg-primary aspectOne"
             src="/assets/images/document.png">
    </ng-container>
    <mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate"></mat-progress-bar>
</ng-container>
