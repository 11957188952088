import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SnackbarService} from "../../_services/snackbar.service";
import {AuthService} from "../../_services/auth.service";
import {NotificationsService} from "../../_services/notifications.service";

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrl: './notification.component.scss'
})
export class NotificationComponent implements OnInit {
    @Input() notification: any;
    @Input() read: boolean = false;
    currentUser: any;
    @Output() markReadOutput: EventEmitter<number> = new EventEmitter();

    link: {
        title: string
        url: string
    } | null = null;

    constructor(private notificationService: NotificationsService,
                private snackBarService: SnackbarService,
                private authService: AuthService,) {
        this.currentUser = this.authService.getUser();

    }

    ngOnInit(): void {
        this.link = this.notificationService.getNotificationLink(this.notification);
    }

    markRead(notification: any): void {
        this.notificationService.markNotificationRead(notification.id).subscribe(r => {
            this.markReadOutput.emit(notification.id);
        }, e => {
            console.error(e);
            this.snackBarService.openSnackBar(e, 'error')
        })
    }

}
