<main class="mediumContainer">
    <div class="d-flex flex-row">
        <button (click)="back()" (keyup.enter)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
            <mat-icon>keyboard_backspace</mat-icon>
        </button>
    </div>
    <div>
        <div class="pt-10">
            <div class="d-flex flex-row flex-space-between">
                <div class="flex-col">
                    <h1 class="my-0">Hidden Media</h1>
                    <h2 class="my-10 f-16">{{ subtitle }}</h2>
                </div>
            </div>
            <div *ngIf="loading" class="flex-row justify-content-center w-100">
                <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
            </div>
            <div>
                <ng-container *ngIf="!loading">
                    <div *ngIf="media.length > 0" class="picsContainer">
                        <mat-card *ngFor="let file of media; let i = index"
                                  aria-label="View Picture" class="clickable imageCard">
                            <app-media-display (viewImage)="viewImage(i)" [file]="file"
                                               [small]="true"></app-media-display>
                            <mat-card-header (click)="viewImage(i)" (keyup.enter)="viewImage(i)" class="pb-16">
                                <mat-card-title>{{ file.title  | ellipsis:40 }}</mat-card-title>
                                <mat-card-subtitle *ngIf="file.description">{{ file.description| ellipsis:65 }}
                                </mat-card-subtitle>
                            </mat-card-header>
                        </mat-card>
                    </div>
                    <p *ngIf="media.length <= 0">
                        Looks like there is no hidden media.
                    </p>
                </ng-container>
                <mat-paginator (page)="handlePageEvent($event)" [hidePageSize]='true' [length]="count"
                               [pageSize]="this.limit"
                               aria-label="Select page"></mat-paginator>
            </div>
        </div>
    </div>
</main>


