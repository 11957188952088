import {ChangeDetectorRef, Component, HostListener, ViewChild} from '@angular/core';
import {read, utils, WorkBook} from 'xlsx';
import {MatTableDataSource} from "@angular/material/table";
import {SnackbarService} from "../../_services/snackbar.service";
import {AdminService} from "../../_services/admin.service";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EditNameComponent} from "../shared/edit-name/edit-name.component";
import {AuthService} from "../../_services/auth.service";
import {FormArray, FormBuilder, FormGroup} from "@angular/forms";
import * as moment from 'moment';
import {AddAccessComponent} from "./add-access/add-access.component";
import {map, of} from "rxjs";
import {Router} from "@angular/router";
import {ConfirmDialogComponent} from "../../shared/confirm-dialog/confirm-dialog.component";
import {ConfirmationDialogData} from "../../_types/shared";
import {MatStepper} from "@angular/material/stepper";

interface BulkInviteJson {
    email_address: string,
    secondary_email?: string,
    role: number,
    first_name: string,
    last_name: string,
    dob?: string,
    reference_number?: string,
    locality?: number,
    send_to_secondary_email_address_only?: boolean,
    cohort_name?: string,
    job_title?: string,
    timeline_user_access?: {
        email: string,
        permission: string
    }[]
}

@Component({
    selector: 'app-user-invite',
    templateUrl: './user-invite.component.html',
    styleUrl: './user-invite.component.scss'
})
export class UserInviteComponent {
    @ViewChild('stepper') stepper: MatStepper | null = null;

    displayedColumns: string[] = ['First Name', 'Last Name', 'User Type/Role', 'Email', 'Date of Birth', 'Secondary Email', 'Case management number', 'Locality', 'Cohort', 'Job title'];
    tableColumns: string[] = ['Delete'].concat(this.displayedColumns);
    permissionDisplayedColumns: string[] = ['First Name', 'Last Name', 'Access'];
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    cohorts: any[] = [];
    localities: any[] = [];
    roles: any[] = [];
    columnNames: any = []
    wb: WorkBook | null = null;
    headerSetting: FormGroup = this.fb.group({
        headers: this.fb.array([])
    });
    loading: boolean = false;
    requiredColumns: string[] = ['First Name', 'Last Name', 'User Type/Role', 'Email']
    emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    constructor(private adminService: AdminService,
                private readonly fb: FormBuilder,
                private authService: AuthService,
                private snackbarService: SnackbarService,
                public dialog: MatDialog,
                private router: Router,
                private changeDetectorRefs: ChangeDetectorRef) {
        this.getCohorts();
        this.getLocalities();
        this.adminService.getUserRoles().subscribe(r => {
            this.roles = r.results;
        }, e => {
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    @HostListener('window:beforeunload', ['$event'])
    handleClose(e: BeforeUnloadEvent): void {
        if ((this.dataSource.data.length > 0 || this.wb)) {
            console.log('test');
            e.preventDefault();
            e.returnValue = 'test';
        }
    }

    headerFormArray(): FormArray {
        return this.headerSetting.get('headers') as FormArray;
    }

    headerFormControl(i: number): FormGroup {
        return this.headerFormArray().at(i) as FormGroup;
    }

    getCohorts(): void {
        this.adminService.getCohorts(0, 1000).subscribe(r => {
            this.cohorts = r.results;

        }, e => {
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    getLocalities(): void {
        this.adminService.getLocalities(0, 1000).subscribe(r => {
            this.localities = r.results;
        }, e => {
            this.snackbarService.openSnackBar(e, 'error');
        })
    }

    getLongestArray(array1: any[], array2: any[], array3: any[]): any[] {
        const length1 = array1?.length || 0;
        const length2 = array2?.length || 0;
        const length3 = array3?.length || 0;

        if (length1 >= length2 && length1 >= length3) {
            return array1 || [];
        } else if (length2 >= length1 && length2 >= length3) {
            return array2 || [];
        } else {
            return array3 || [];
        }
    }

    handleImport($event: any): void {
        this.headerSetting = this.fb.group({
            headers: this.fb.array([])
        });
        const files = $event.target.files;
        if (files.length) {
            const file = files[0];
            const reader: FileReader = new FileReader();
            reader.onload = (event: any): void => {
                this.wb = read(event.target.result, {cellDates: true});
                const sheets: string[] = this.wb.SheetNames;
                const columns: any[] = utils.sheet_to_json(this.wb.Sheets[sheets[0]], {header: 1});
                // Get the first 3 rows if they exist, filtering out empty values
                const firstThreeRows = columns.slice(0, 3).map(row =>
                    row ? row.filter((e: any) => e) : []
                );
                // The biggest of the first 3 rows is probably the header titles
                this.columnNames = this.getLongestArray(
                    firstThreeRows[0] || [],
                    firstThreeRows[1] || [],
                    firstThreeRows[2] || []
                );
                for (let i: number = 0; i < this.columnNames.length; i++) {
                    this.headerFormArray().push(
                        this.fb.group({
                            newHeader: this.fb.control({value: this.displayedColumns[i] || null, disabled: false}),
                            oldHeader: this.fb.control({value: this.columnNames[i], disabled: false}),
                        }));
                }
            }
            reader.readAsArrayBuffer(file);
            this.snackbarService.openSnackBar('File read successfully', 'success')
        }
    }

    addUserRow(firstAdd: boolean = false): void {
        this.authService.refreshUser();
        if (!firstAdd || (firstAdd && this.dataSource.data.length < 1)) {

            let user = {
                'Case management number': null,
                'Cohort': null,
                'Date of Birth': null,
                'Email': null,
                'First Name': null,
                'Job title': null,
                'Last Name': null,
                'Locality': null,
                'Secondary Email': null,
                'User Type/Role': null
            };
            const newData = [...this.dataSource.data];
            newData.push(user);
            this.dataSource.data = newData;
            this.changeDetectorRefs.detectChanges();
        }
    }

    readData(): void {
        // Stop user from timing out
        this.authService.refreshUser();
        if (this.wb) {
            const sheets: string[] = this.wb.SheetNames;
            if (sheets.length) {
                const rows: any[] = utils.sheet_to_json(this.wb.Sheets[sheets[0]], {header: 1});
                let newRows = []
                const i = rows.findIndex(x => JSON.stringify(x) === JSON.stringify(this.columnNames));
                if (i !== -1) {
                    rows.splice(0, i + 1);
                }
                if (rows.length > 0) {
                    // change key from oldheader to new header set in step one
                    for (let item of rows) {
                        let newItem: any = {};

                        for (let control of this.headerFormArray().controls) {
                            const newHeader = control.get('newHeader')?.value;
                            const oldHeader = control.get('oldHeader')?.value;
                            if (newHeader) {
                                const i: number = this.columnNames.findIndex((x: any): boolean => x == oldHeader);
                                newItem[newHeader] = item[i];
                            }
                        }
                        item = newItem;
                        for (const [key, value] of Object.entries(item)) {
                            if (!this.displayedColumns.includes(key)) {
                                delete item[key]
                            }
                        }
                        let localityId: number = this.getArrayItemId(item['Locality'], this.localities);
                        if (localityId && localityId >= 0) {
                            item['Locality'] = localityId
                        } else if (item['Locality'] != undefined) {
                            item['localityError'] = '"' + item['Locality'] + '" is not a valid locality.'
                            item['Locality'] = null;
                        } else {
                            item['Locality'] = null;
                        }

                        let cohortId: number = this.getArrayItemId(item['Cohort'], this.cohorts);
                        if (cohortId && cohortId >= 0) {
                            item['Cohort'] = localityId
                        } else if (item['Cohort'] != undefined) {
                            item['cohortError'] = '"' + item['Cohort'] + '" is not a valid Cohort.'
                            item['Cohort'] = null;
                        } else {
                            item['Cohort'] = null;
                        }

                        let roleId: number = this.getArrayItemId(item['User Type/Role'], this.roles, true);
                        if (roleId && roleId >= 0) {
                            item['User Type/Role'] = roleId
                        } else {
                            item['roleError'] = '"' + item['User Type/Role'] + '" is not a valid Role.'
                            item['User Type/Role'] = null;
                        }

                        // if (item['Send invite to secondary email only'] && item['Send invite to secondary email only']?.toLowerCase() == 'yes' && item['Send invite to secondary email only']?.toLowerCase() != 'true') {
                        //     item['Send invite to secondary email only'] = true
                        // } else {
                        //     item['Send invite to secondary email only'] = false
                        // }


                        if (item['Date of Birth']) {
                            // use custom parsing options first
                            let date = moment(item['Date of Birth'], ['DD/MM/YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'DD.MM.YYYY'])
                            if (date.isValid()) {
                                item['Date of Birth'] = date.toDate()
                            } else {
                                // if custom doesnt work use moment default parsing but may lead to unexpected results for ambiguous formats (e.g., 01/02/2023 could mean January 2nd or February 1st depending on locale)
                                item['Date of Birth'] = moment(item['Date of Birth']).toDate()
                            }
                        }
                        newRows.push(item)
                    }
                } else {
                    this.snackbarService.openSnackBar('No data in file', 'error')
                }
                this.dataSource = new MatTableDataSource(newRows);
            }
        }
    }

    getArrayItemId(name: string, array: any[], fuzzySearch?: boolean): number {
        if (name) {
            let i: number = -1;
            for (let item of array) {
                if (item?.name?.toLowerCase() == name.toLowerCase() || (fuzzySearch && item?.name?.toLowerCase().includes(name.toLowerCase()))) {
                    i = item.id;
                }
            }
            return i;
        } else {
            return -1;
        }
    }

    newLocalityModal(): void {
        const dialogRef: MatDialogRef<EditNameComponent> = this.dialog.open(EditNameComponent, {
            data: {
                title: 'Create locality',
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.newLocality(resp).subscribe(r => {
                    this.snackbarService.openSnackBar('New locality created', 'success');
                    this.getLocalities();
                }, e => {
                    console.error(e);
                    this.snackbarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    newCohortModal(): void {
        const dialogRef: MatDialogRef<EditNameComponent> = this.dialog.open(EditNameComponent, {
            data: {
                title: 'Create Cohort',
            },
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.adminService.newCohort(resp).subscribe(r => {
                    this.snackbarService.openSnackBar('New cohort created', 'success');
                    this.getCohorts();
                }, e => {
                    console.error(e);
                    this.snackbarService.openSnackBar(e, 'error')
                })
            }
        });
    }

    getOccurrence(value: string): number {
        let exists: number = 0;
        if (value) {
            for (let header of this.headerFormArray().controls) {
                if (header.value.newHeader == value) {
                    exists++
                }
            }
        }
        return exists;
    }

    hasAnyDuplicates(): boolean {
        const seenIds = new Set();
        for (let control of this.headerFormArray().controls) {
            const id = control.get('newHeader')?.value;

            if (id && seenIds.has(id)) {
                return true;
            }

            seenIds.add(id);
        }

        return false;
    }

    validDataCheck(): boolean {
        let valid: boolean = true
        for (let item of this.dataSource.data) {
            for (let requiredColumn of this.requiredColumns) {
                if (!item[requiredColumn] || item[requiredColumn].length <= 0) {
                    valid = false;
                }
            }
        }

        for (let item of this.dataSource.data) {
            if (!item['Date of Birth'] && this.isChild(item['User Type/Role'])) {
                valid = false;
            }
        }
        return valid
    }


    isChild(role: string): boolean {
        return role == '11' || role == '12' || role == '13'
    }

    addPermission(user: any): void {

        const dialogRef: MatDialogRef<AddAccessComponent> = this.dialog.open(AddAccessComponent, {
            data: {
                user: user,
                access: user['timeline_user_access'],
                possibleUsers: this.dataSource.data
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                if (user['timeline_user_access'] && Array.isArray(user['timeline_user_access'])) {
                    user['timeline_user_access'].push(resp)
                } else {
                    user['timeline_user_access'] = [resp];
                }
            }
        });

    }

    removePermission(user: any, currentPermission: any): void {
        user['timeline_user_access'] = user['timeline_user_access'].filter((permission: any) => permission != currentPermission);
    }

    sendInvites(): void {
        const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
            panelClass: 'mediumWindow',
            data: {
                showSubmitBtn: true,
                showCancelBtn: true,
                message: 'Are you sure you want to send these invites',
                title: 'Send Invites',
                showHeader: true
            }
        });
        dialogRef.afterClosed().subscribe((resp): void => {
            if (resp) {
                this.loading = true;
                of(this.dataSource.data).pipe(
                    map(dataArray => dataArray.map(data => {
                        // Map and transform fields
                        const transformed = {
                            email_address: data["Email"],
                            secondary_email: data["Secondary Email"] || null,
                            role: parseInt(data["User Type/Role"]),
                            first_name: data["First Name"],
                            last_name: data["Last Name"],
                            dob: data["Date of Birth"] ? moment(data["Date of Birth"])?.format('YYYY-MM-DD') : null,
                            reference_number: data["Case management number"]?.toString() || null,
                            locality: data["Locality"] || null,
                            send_to_secondary_email_address_only: data["Date of Birth"] == 'true',
                            cohort_name: data["Cohort"] || null,
                            job_title: data["Job title"] || null,
                            timeline_user_access: data.timeline_user_access ? data.timeline_user_access : null
                        };
                        if (Object.prototype.toString.call(transformed.dob) !== '[object Date]') {
                            transformed.dob = null;
                        }

                        // Remove null fields
                        return this.removeNullFields(transformed);
                    }))
                ).subscribe(result => {
                    function myFilter(elm: any): boolean {
                        return (elm != null);
                    }

                    const body = result.filter(myFilter);
                    this.adminService.bulkJSONInvite(body).subscribe(r => {
                            this.loading = false;
                            this.snackbarService.openSnackBar(r.detail, 'success');
                            this.router.navigate(['/bulkInvite', r.job_id]);
                        }, e => {
                            this.loading = false;
                            this.snackbarService.openSnackBar(e, 'error');
                        }
                    )
                });
            }
        });
    }

    removeNullFields(obj: any) {
        return Object.keys(obj).reduce((acc: any, key) => {
            if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
                acc[key] = obj[key];
            }
            return acc;
        }, {});
    };

    clearAllNonChildAccess(): void {
        let childrenPresent: boolean = false;
        // None child users shouldn't have timeline_user_access
        for (let user of this.dataSource.data) {
            if (user['User Type/Role'] !== '11' && user['User Type/Role'] !== '12' && user['User Type/Role'] !== '13') {
                delete user.timeline_user_access
            } else {
                childrenPresent = true;
            }
        }
        // Check if there are any children users if not then skip directly to upload
        if (!childrenPresent) {
            this.sendInvites()
        }
    }

    delete(index: any): void {
        if (index > -1) {
            const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.dialog.open<ConfirmDialogComponent, ConfirmationDialogData>(ConfirmDialogComponent, {
                panelClass: 'mediumWindow',
                data: {
                    showSubmitBtn: true,
                    showCancelBtn: true,
                    message: 'Are you sure you want to delete this row?',
                    title: 'Delete Row',
                    showHeader: true
                }
            });
            dialogRef.afterClosed().subscribe((resp): void => {
                if (resp) {
                    this.dataSource.data.splice(index, 1);
                    this.dataSource.data = [...this.dataSource.data];
                }
            });
        }

    }

    back(): void {
        if ((this.dataSource.data.length > 0 || this.wb) && confirm('Are you sure you want to leave? Changes that you have made may not be saved.')) {
            history.back();
        }
    }

    backCheck() {
        if (confirm('Are you sure you want to go back? Changes that you have made may not be saved.')) {
            this.stepper?.previous()
        }
    }
}
