<table [dataSource]="dataSource" class="responsiveTable" mat-table matSort (matSortChange)="sortData($event)"  [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault">

    <ng-container matColumnDef="date">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Date Raised</th>
        <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Date
            Raised:</b> {{ media.created_date | date :'medium' }}
        </td>
    </ng-container>
    <ng-container matColumnDef="user">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Requester</th>
        <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Requester:</b> {{ media.creator?.full_name }}
        </td>
    </ng-container>
    <ng-container matColumnDef="box">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Box</th>
        <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Box:</b>{{ media.timeline?.title }}</td>
    </ng-container>
    <ng-container matColumnDef="category">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>Category</th>
        <td *matCellDef="let media" mat-cell><b class="d-none columnTitle">Category:</b>{{ media.category }}</td>
    </ng-container>
    <ng-container matColumnDef="view">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>View</th>
        <td *matCellDef="let media" mat-cell>
            <button [routerLink]="['/flaggedMedia', media.id]" color="primary"
                    mat-raised-button>
                View Flag
            </button>
        </td>
    </ng-container>
    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    <tr *matNoDataRow class="mat-row">
        <td *ngIf="this.filter" [attr.colspan]="this.displayedColumns.length" class="mat-cell text-center">
            No data matching the filter "{{ this.filter }}"
        </td>
    </tr>
</table>
<mat-paginator (page)="this.handlePageEvent.emit($event)" [length]="count" [pageSizeOptions]="[10, 15, 25, 50]"
               [pageSize]="limit" aria-label="Select page"></mat-paginator>
