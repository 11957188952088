<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <button [routerLink]="['/timeline']" aria-label="Home" mat-icon-button matTooltip="Home">
        <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
    </button>
</mat-toolbar>
<main class="notFoundBannerBg minFullHeight" role="main">
    <div class="container d-flex justify-content-center">
        <div class="d-flex flex-column">

            <div class="text-center">
                <h1 class="mx-auto">404</h1>
                <h2>Page not found</h2>
                <button (click)="goToHome()" (keyup.enter)="goToHome()" class="mx-auto my-3" color="primary"
                        mat-flat-button>Back to safety
                </button>
            </div>
        </div>
    </div>
</main>
<app-footer></app-footer>
