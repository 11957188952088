<mat-toolbar class="d-flex flex-space-between mat-elevation-z3" color="primary">
    <button [routerLink]="['/timeline']" aria-label="Home" mat-icon-button matTooltip="Home">
        <img alt="Virtual Memory Box Logo" src="assets/images/vmb.svg">
    </button>
</mat-toolbar>
<main *ngIf="settings">
    <div class="mediumContainer">
        <div class="d-flex justify-content-center">
            <h1 class="mx-auto">Terms & Conditions</h1>
        </div>

        <div *ngIf="!termsDynamic && settings">
            <h2 class="text-center"><strong>{{ settings.authority_name }}, Children's Social Care</strong></h2>
            <h3 class="text-center"><strong>Virtual Memory Box Terms & Conditions </strong></h3>
            <p>Unacceptable or inappropriate use of Virtual Memory Box exposes the user, the Council and
                contents to risks which could damage the Council&rsquo;s reputation, harm people and cause legal issues.
                Access and use of Virtual Memory Box and all information contained in and relating to is monitored,
                therefore privacy cannot be assumed. This policy applies to all persons using Virtual Memory Box and it
                is the responsibility of all users to accept the terms of this policy and seek guidance if unsure.</p>
            <p>The council retains all rights to grant, remove and limit access to the Virtual Memory Box in
                order to protect it, its contents and its account holders.</p>
            <strong>1. Information Confidentiality</strong>
            <p>Personal and Special Category information must not be:</p>
            <p>a. Disclosed to or discussed with unauthorised persons.</p>
            <p>b. Removed and Stored on portable media such as CD, DVD or USB storage devices; unless
                authorised to do so and encrypted.</p>
            <p>c. Council employees must not store or transfer files and information on non-Council equipment
                or send via non-Council or personal e-mail accounts.</p>
            <p>d. Used to gain access to or amend information / others Virtual Memory Boxes which you are not
                authorised to do so.</p>
            <p>e. Used to access any other than your own records or those of any person linked to you through
                Children's Social Care employee contact or where you are responsible for the child'&rsquo;'s current
                care.</p>
            <p><br></p>
            <strong>2. Legal Obligations</strong>
            <p>All users must:<br>a. Adhere to the Data Protection Act 2018 and the General Data
                Protection Regulation (GDPR). Staff should refer to the Councils Data Protection Policy.</p>
            <p>b. Comply with applicable legislation and if you are unsure seek guidance.</p>
            <p>c. Not use the Virtual Memory Box to engage in any illegal activity.</p>
            <p>d. Only share personal and special category information using approved secure methods directly
                via the Virtual Memory Box.</p>
            <p>e. Use only licensed software and abide by copyright and licensing laws. Permission must be
                obtained to use licensed music, video, TV programmes or website downloads or to digitise.</p>
            <p><br></p>
            <p>It is a criminal offence to:</p>
            <p>f. Knowingly or recklessly disclose / process personal information without authorisation.</p>
            <p>g. Use information for a purpose other than for which it was originally intended.</p>
            <p>h. Re-identify individuals from data that has been anonymised.</p>
            <p>i. Alter or prevent the disclosure of personal data that has been subject to an access request.</p>
            <p><br></p>
            <strong>4. Inappropriate Material</strong>
            <p>a. Carers should ensure that <span><strong>all</strong></span>
                uploaded content for children they are responsible for is appropriate and fully meets the requirements
                of this policy.</p>
            <p>b. It is strictly prohibited to attempt to access, view, send, circulate, comment, tag or store material
                that is defined as inappropriate material at any time.</p>
            <p>c. Inappropriate use of language, tags and files should be deemed as inappropriate materials.</p>
            <p><br></p>
            <p>Inappropriate Content (includes but not limited to):</p>
            <p>- Exposure to inappropriate content, including online pornography, unsuitable age ratings in films and
                games etc, substance abuse</p>
            <p>- promoting harmful behaviours</p>
            <p>- Hate content</p>
            <p>- Grooming (sexual exploitation, radicalisation etc.)</p>
            <p>- Online bullying in all forms</p>
            <p>- Social or commercial identity theft, including passwords</p>
            <p>- Aggressive behaviours such as bullying and intimidation</p>
            <p>- Privacy issues, including disclosure of personal information</p>
            <p>- Negative Health and well-being including online gambling, body image, internet or gaming;</p>
            <p>- indecent images of any type</p>
            <p>- Copyright and intellectual property theft &ndash; such as music and film.</p>
            <p><br></p>
            <strong>5. Workspace Security</strong>
            <p>a. Always log out of the Virtual Memory Box and other ICT equipment when not in use, or lock screens.</p>
            <p>b. When working in public environments (trains, cafes etc) be mindful of people around you and
                whether they can see personal or confidential information on your screen or hear you talking.</p>
            <p><br></p>
            <strong>6. Login information and Passwords</strong>
            <p>a. You are responsible for all activity under your login, which is monitored.</p>
            <p>b. Never write down or share your login or password with anyone, including employees
                of {{ settings.authority_name }} or partner Nebula Labs.</p>
            <p>c. Passwords should be strong, including a mix of letters, numbers and special characters for
                maximum security.</p>
            <p><br></p>
            <strong>9. Cyber Security</strong>
            <p>a. You are responsible for checking files are not corrupt and any attachments are scanned for viruses
                before uploading.</p>
            <p>b. If you believe a file is suspicious, do not click any links, download any attachments, enter any type
                of information or data (personal details, passwords, financial information etc.) and contact to the
                Virtual Memory Box email and report it immediately.</p>
            <p><br></p>
            <strong>10. ICT &amp; Information Security Incidents</strong>
            <p>a. All security incidents, perceived weaknesses or suspected data breaches must be reported
                to {{ settings.authority_name }} immediately. Carers should contact the Virtual Memory Box email;
                employees should contact Information Security directly.</p>
            <p><br></p>
            <strong>12. Personal Equipment</strong>
            <p>a. Employees of {{ settings.authority_name }} should only authorised personal IT equipment. Memory
                sticks, mobile phones and other devices can be used for Council business but must not be attached to
                Council systems or used to take unauthorised photos, videos or audio recordings at work.</p>
            <p><br></p>
            <strong>13. Policy Compliance &amp; Disciplinary</strong>
            <p>a. Employees - Policy breaches will be taken seriously and may be considered as misconduct or gross
                misconduct resulting in disciplinary and in some cases legal action.</p>
            <p>b. All other users - Policy breaches will be taken seriously and necessary remedial actions will be put
                in place to protect the Virtual Memory Box, its contents and legal requirements this may include, but is
                not be limited to, removal of access to files and folders, removal of files and comments and other
                limitations and in some cases legal action may be considered.</p>
            <p><br></p>
            <strong>14. Advice, assistance and support</strong>
            <p>a. If you require further information, advice or guidance please {{ settings.authority_name }}via the
                Virtual Memory Box email where employees will be happy to help.</p>
        </div>
        <div [innerHTML]="termsDynamic">

        </div>
        <div *ngIf="!this.user?.agreed_tos && this.user" class="w-100 text-center mb-10">
            <button (click)="acceptTerms()" (keyup.enter)="acceptTerms()" color="primary" mat-raised-button>Accept
                Terms
            </button>
        </div>
    </div>
</main>
<app-footer></app-footer>
