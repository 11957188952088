<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            {{ resolved ? 'Resolved' : 'Unresolved' }} Permission Requests
        </h3>
    </div>
    <div class="largeContainer">
        <div class="flex-col align-items-end mb-10">
            <button (click)="switchFilters()" class="w-content" color="primary" mat-raised-button>
                View  {{ resolved ? 'Unresolved' : 'Resolved' }} Permission Requests
            </button>
        </div>
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div *ngIf="!loading" class="mat-elevation-z6">
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around">
                <mat-form-field class="noBottomPadding w-100">
                    <mat-label>Search Permission Requests</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getPermissionRequests()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getPermissionRequests()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search  Permission Requests">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <app-permission-requests-table (handlePageEvent)="pageChange($event)" (orderChange)="changeOrder($event)" [count]="count"
                                           [data]="permissionRequests"
                                           [limit]="limit"></app-permission-requests-table>
        </div>
    </div>
</main>
