import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'removeSnakecase',
    standalone: true
})
export class RemoveSnakecasePipe implements PipeTransform {

    transform(value: string, ...args: unknown[]): unknown {
        let string = '';
        if (value) {
            string = value.replace(/_/g, " ");
        }
        return string.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

}
