<main>
    <div class="largeContainer">
        <div class="d-flex flex-row flex-space-between">
            <button (click)="back()" aria-label="Back" mat-icon-button matTooltip="Back">
                <mat-icon>keyboard_backspace</mat-icon>
            </button>
            <div><h3 class="my-10">
                Bulk Invite Job
            </h3></div>
            <div></div>
        </div>
        <div class="flex-col gap-25">
            <mat-card>
                <mat-card-content>
                    <table [dataSource]="dataSource" mat-table class="responsiveTable w-100"
                           *ngIf="this.dataSource.data.length > 0">
                        <ng-container matColumnDef="status">
                            <th *matHeaderCellDef mat-header-cell>Status</th>
                            <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">
                                Status:</b> {{ user.status || 'Pending'| removeSnakecase }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="First Name">
                            <th *matHeaderCellDef mat-header-cell>First Name</th>
                            <td *matCellDef="let user" mat-cell><b class="d-none columnTitle">
                                First Name:</b> {{ user.first_name }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="Last Name">
                            <th *matHeaderCellDef mat-header-cell>Last Name</th>
                            <td *matCellDef="let user" mat-cell>
                                <b class="d-none columnTitle">
                                    Last Name:</b> {{ user.last_name }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="username">
                            <th *matHeaderCellDef mat-header-cell>Username</th>
                            <td *matCellDef="let user" mat-cell>
                                <ng-container *ngIf="user.user else loadingWheel">
                                    <b class="d-none columnTitle">
                                        Username:</b> {{ user.user?.username }}
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="view">
                            <th *matHeaderCellDef mat-header-cell>View User</th>
                            <td *matCellDef="let user" mat-cell>
                                <a *ngIf="user.user else loadingWheel" mat-raised-button color="primary"
                                   class="text-center"
                                   [routerLink]="user.timeline?.id ? ['/user', user.user.id, user.user.timeline?.id] : ['/user', user.user.id]">
                                    View User
                                </a>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="access">
                            <th *matHeaderCellDef mat-header-cell>Access</th>
                            <td *matCellDef="let user" mat-cell>
                                <ng-container *ngIf="user.user else loadingWheel">
                                    <button mat-raised-button color="primary" *ngIf="user.user.timeline" (click)="addPermission(user.user)">
                                        Add Access
                                    </button>
                                </ng-container>
                            </td>
                        </ng-container>
                        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </table>
                </mat-card-content>
                <mat-card-footer *ngIf="loading">
                    <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
                </mat-card-footer>
            </mat-card>
        </div>
    </div>
</main>
<ng-template #loadingWheel>
    <mat-icon>
        <mat-spinner color="primary"></mat-spinner>
    </mat-icon>
</ng-template>
