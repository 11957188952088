<mat-dialog-content aria-modal="true" class="d-flex flex-col" role="dialog">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">{{ this.title || 'Select Users' }}</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button (keyup.enter)="this.dialogRef.close();" class="mat-dialog-close" mat-dialog-close mat-icon-button
                    matTooltip="Close Window">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <form class="pt-10">
        <mat-form-field appearance="outline" class="noBottomPadding w-100" color="primary">
            <mat-label>Select Users</mat-label>
            <input #userInput [formControl]="userSearchControl"

                   [matAutocomplete]="auto"
                   [matChipInputFor]="chipList"
                   matInput
                   placeholder="Select Users">
            <mat-autocomplete #auto="matAutocomplete"
                              (optionSelected)="selectUser($event.option.value)">
                <ng-container *ngFor="let user of filteredUsers ">
                    <mat-option *ngIf="!checkIfInArray(user, this.selectedUsers)"
                                [value]="user">
                        {{ user.full_name && user.full_name != ' ' ? user.full_name + ' (' + user.username + ')' : (user.username || user.email) }}

                    </mat-option>
                </ng-container>
            </mat-autocomplete>
            <mat-icon *ngIf="loadingUsers" matSuffix>
                <mat-spinner color="primary"></mat-spinner>
            </mat-icon>
        </mat-form-field>
        <mat-chip-grid #chipList aria-label="Users Selection">
            <mat-chip (removed)="removeUser(user)" *ngFor="let user of this.selectedUsers"
                      class="smChip">
                {{ user.full_name && user.full_name != ' ' ? user.full_name + ' (' + user.username + ')' : (user.username || user.email) }}
                <button matChipRemove>
                    <mat-icon>cancel</mat-icon>
                </button>
            </mat-chip>
        </mat-chip-grid>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="flex-space-around">
    <div *ngIf="bulkInvite" class="flex-col">
        <button (click)="fileInput.click()" (keyup.enter)="fileInput.click()"
                color="accent" mat-raised-button>
            <span>Bulk Add Users From CSV</span>
            <mat-icon>attach_file</mat-icon>
            <input #fileInput (change)="onFileChanged($event)"
                   accept="text/csv" class="d-none"
                   type="file"/>
        </button>
        <small class="mt-5"><a download href="assets/VMB_link_users_to_cohorts_template.csv">Download CSV
            template</a></small>
    </div>
</mat-dialog-actions>
<mat-dialog-actions class="flex-space-around">
    <button (keyup.enter)="this.dialogRef.close();" color="warn" mat-dialog-close
            mat-raised-button>
        Cancel
    </button>
    <button (click)="this.dialogRef.close(this.selectedUsers);"
            *ngIf="this.selectedUsers && this.selectedUsers.length > 0 && !loadingUsers"
            color="primary" mat-raised-button>
        Save
    </button>
</mat-dialog-actions>

