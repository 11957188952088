import {Component, OnDestroy} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SnackbarService} from "../../../_services/snackbar.service";
import {AdminService} from "../../../_services/admin.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NewPermissionComponent} from "../../users/user/new-permission/new-permission.component";
import {ContentService} from "../../../_services/content.service";

@Component({
    selector: 'app-invite-job',
    templateUrl: './invite-job.component.html',
    styleUrl: './invite-job.component.scss'
})
export class InviteJobComponent implements OnDestroy {
    loading: boolean = true;
    id: number | undefined;
    interval: any;
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumns: string[] = ['status', 'First Name', 'Last Name', 'username', 'view', 'access'];


    constructor(private route: ActivatedRoute,
                private adminService: AdminService,
                public dialog: MatDialog,
                private contentService: ContentService,
                private snackbarService: SnackbarService,) {
        this.route.params
            .subscribe(params => {
                this.id = params['id'];
                if (this.id) {
                    this.getTimelineJob(this.id);
                    this.getInviteJobInterval(this.id);
                }
            }, error => {
                this.snackbarService.openSnackBar(error, 'error')
                console.error(error);
            });
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    getInviteJobInterval(id: number) {
        let that: this = this;
        this.interval = setInterval(function (): void {
            that.getTimelineJob(id);
        }, 6000);
    }

    getTimelineJob(id: number) {
        this.adminService.getInviteJob(id).subscribe(r => {
            if (r.users.length > 0) {
                this.dataSource.data = r.users;
                if (r.status != "in_progress") {
                    this.loading = false;
                    clearInterval(this.interval);
                }
            } else {
                this.loading = false;
                clearInterval(this.interval);
                this.snackbarService.openSnackBar('No users in job', 'error')
            }
        }, e => {
            console.error(e)
        })
    }

    back(): void {
        history.back();
    }

    addPermission(user: any) {
        this.contentService.viewTimelinePermissions(user.timeline.id).subscribe(res => {
            this.openPermissionsDialog(user, res)
        }, e => {
            this.openPermissionsDialog(user)
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    openPermissionsDialog(user: any, permissions?: any) {
        const dialogRef: MatDialogRef<NewPermissionComponent> = this.dialog.open(NewPermissionComponent, {
            data: {
                user: user,
                timeline_id: user.timeline.id,
                title: user.first_name || user.username + "'s Timeline",
                currentPermissions: permissions || null,
                displayCurrentPermissions: true
            },
        })
    }
}
