<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Locations
        </h3>
    </div>
    <div class="mediumContainer">
        <div *ngIf="loading" class="centerLoading">
            <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
        </div>
        <div class="flex-row align-items-end mb-10 gap10 justify-content-end">
            <button (click)="newLocalityModal()" color="primary" mat-raised-button>Add New Location</button>
        </div>
        <div [hidden]="loading" class="mat-elevation-z6">
            <div class="flex-row gap-15 bg-white align-items-center flex-space-around justify-content-center filterItems fewItems">
                <mat-form-field class="noBottomPadding w-100">
                    <mat-label>Search Locations</mat-label>
                    <input (keydown)="keydown()" (keyup)="keyup()"
                           (keyup.enter)="getLocations()"
                           [(ngModel)]="searchTerm"
                           matInput
                           type="text"/>
                    <button (click)="getLocations()" [disabled]="!searchTerm"
                            aria-label="Search"
                            mat-icon-button matPrefix matTooltip="Search Locations">
                        <mat-icon color="primary">
                            search
                        </mat-icon>
                    </button>
                    <button (click)="clearSearch()" *ngIf="searchTerm" aria-label="Clear Search" mat-icon-button
                            matSuffix>
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>
            </div>
            <table [dataSource]="dataSource" class="responsiveTable" mat-table matSort
                   [matSortActive]="matSortDefault" [matSortDirection]="matSortDirectionDefault"
                   (matSortChange)="sortData($event)">
                <ng-container matColumnDef="name">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header> Name</th>
                    <td *matCellDef="let location" mat-cell><b class="d-none columnTitle">Name:</b> {{ location.name }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="date">
                    <th *matHeaderCellDef mat-header-cell mat-sort-header>Created Date</th>
                    <td *matCellDef="let location" mat-cell>
                        <b class="d-none columnTitle">Created Date:</b> {{ location.created_date| date }}
                    </td>
                </ng-container>
                <ng-container matColumnDef="view">
                    <th *matHeaderCellDef mat-header-cell>View</th>
                    <td *matCellDef="let location" mat-cell>
                        <button [routerLink]="['/location', location.id]" color="primary" mat-raised-button>
                            View Location
                        </button>
                    </td>
                </ng-container>
                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow class="mat-row">
                    <td *ngIf="searchTerm" [attr.colspan]="this.displayedColumns.length" class="mat-cell text-center">
                        No data matching the filter "{{ searchTerm }}"
                    </td>
                    <td *ngIf="!searchTerm && !loading" [attr.colspan]="this.displayedColumns.length"
                        class="mat-cell text-center">
                        No locations Found
                    </td>
                </tr>
            </table>
        </div>
    </div>
</main>
