<ng-container *ngIf="src">
    <div (click)="thumbnailClick(audioElement, state)" (keyup.enter)="thumbnailClick(audioElement, state)"
         [class.playing]="state == 'play'"
         class="sound-wave">
        <div *ngFor="let bar of waveform" [style.animation-duration]="bar.delay" [style.width]="width"
             class="bar"></div>
    </div>
    <audio #audio #audioElement (contextmenu)="false" (pause)="this.state = 'paused'" (play)="this.checkUrl(src, 'audio',  audio)"
           [preload]="small ? 'none' : 'auto'" [src]="src" [tabIndex]="small ? -1: -1"
           class="w-100" controls
           controlsList="nodownload noplaybackrate">
        Your browser does not support the video tag.
    </audio>
</ng-container>
<div *ngIf="!src" class="d-flex justify-content-center">
    <mat-progress-spinner color="accent" mode="indeterminate"></mat-progress-spinner>
</div>
