import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";

@Component({
    selector: 'app-flagged-table',
    templateUrl: './flagged-table.component.html',
    styleUrl: './flagged-table.component.scss'
})
export class FlaggedTableComponent implements OnChanges, AfterViewInit {
    @Input() data: any[] = [];
    @Input() count: number = 0;
    @Input() filter: string | null = null;
    @Input() limit: number = 0;
    @Input() matSortDefault: string = '';
    @Input() matSortDirectionDefault: "asc" | "desc" = "desc";
    @Output() checked: EventEmitter<boolean> = new EventEmitter();
    displayedColumns: string[] = ['date', 'user', 'box', 'category', 'view'];
    dataSource: MatTableDataSource<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator | null = null;
    @ViewChild(MatSort) sort: MatSort | null = null;
    @Output() orderChange: EventEmitter<any> = new EventEmitter();
    @Output() handlePageEvent: EventEmitter<any> = new EventEmitter();
    protected readonly location = location;

    constructor() {
        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.data);
    }

    ngAfterViewInit(): void {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(): void {
        this.dataSource.data = this.data;
    }

    sortData(event: Sort): void {
        this.orderChange.emit(event)
    }
}
