<mat-dialog-content aria-modal="true" class="d-flex flex-col" role="dialog">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">{{ data.title }}</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button (keyup.enter)="this.dialogRef.close();" class="mat-dialog-close" mat-dialog-close mat-icon-button
                    matTooltip="Close Window">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <form [formGroup]="editNameForm" class="pt-10">
        <mat-form-field appearance="outline" class="w-100">
            <mat-label>Name</mat-label>
            <input formControlName="name" matInput maxlength="100" placeholder="Name">
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions class="flex-space-around">
    <button (keyup.enter)="this.dialogRef.close();" [disabled]="loading" color="warn" mat-dialog-close
            mat-raised-button>
        Cancel
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
    <button [disabled]="editNameForm.invalid || loading" [mat-dialog-close]="this.editNameForm.value.name"
            color="primary" mat-raised-button>
        Save
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
</mat-dialog-actions>

