import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ConfirmationDialogData} from "../../_types/shared";

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrl: './confirm-dialog.component.scss'
})
export class ConfirmDialogComponent {

    constructor(
        private readonly matDialog: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) protected data: ConfirmationDialogData | null
    ) {
    }
}
