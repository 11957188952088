<div>
    <mat-card-header *ngIf="data?.showHeader" class="flex-row flex-space-between p-20 bg-accent">
        <mat-card-title>
            {{ data?.title | titlecase }}
        </mat-card-title>
        <div class="float-end">
            <button [mat-dialog-close]="false" class="mat-dialog-close close-icon-button" mat-icon-button
                    matTooltip="Close Modal"
                    tabindex="-1">
                <mat-icon class="accent-text">close</mat-icon>
            </button>
        </div>
    </mat-card-header>
    <mat-dialog-content>
        <div class="p-20 pb-10">
            <p [innerHTML]="data?.message" class="content-message"></p>
        </div>
    </mat-dialog-content>
</div>
<mat-dialog-actions>
    <div class="w-100 d-flex flex-space-around pb-15">
        <button *ngIf="data?.showCancelBtn" [mat-dialog-close]="false" color="warn" mat-raised-button>
            {{ data?.cancelBtnText ?? 'Cancel' }}
        </button>
        <button *ngIf="data?.showSubmitBtn" [mat-dialog-close]="true" color="accent"
                mat-raised-button>
            {{ data?.submitBtnText ?? 'Confirm' }}
        </button>
    </div>
</mat-dialog-actions>
