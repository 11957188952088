import {Component} from '@angular/core';
import {AdminService} from "../../../_services/admin.service";
import {MatDialog} from "@angular/material/dialog";
import {SnackbarService} from "../../../_services/snackbar.service";
import {MatTableDataSource} from "@angular/material/table";

@Component({
    selector: 'app-invite-jobs',
    templateUrl: './invite-jobs.component.html',
    styleUrl: './invite-jobs.component.scss'
})
export class InviteJobsComponent {
    dataSource: MatTableDataSource<any> = new MatTableDataSource();
    displayedColumns: string[] = ['created', 'status', 'view'];
    loading: boolean = true;

    constructor(private adminService: AdminService,
                public dialog: MatDialog,
                private snackbarService: SnackbarService) {
        this.adminService.getAllInviteJobs().subscribe(r => {
            console.log(r)
            this.dataSource.data = r;
            this.loading = false;
        }, e => {
            this.loading = false;
            console.error(e)
            this.snackbarService.openSnackBar(e, 'error')
        })
    }

    back(): void {
        history.back();
    }
}


