import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ReportingService {

    constructor(private http: HttpClient) {
    }


    reportingOverview(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/overview/`);
    }

    adminReports(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/admin/`);
    }


    usersReports(locality?: string, cohort?: string): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (cohort) {
            parameters = parameters.set('cohorts__cohort', cohort);
        }
        if (locality) {
            parameters = parameters.set('locality', locality);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/user/`, {
            params: parameters,
        });
    }

    timelineReport(csv?: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (csv) {
            parameters = parameters.set('csv', true);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/timelines/`, {
            params: parameters,
        });
    }

    noMemoriesReport(csv?: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (csv) {
            parameters = parameters.set('csv', true);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/timelines/no-memories`, {
            params: parameters,
        });
    }

    invitesPendingReport(csv?: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (csv) {
            parameters = parameters.set('csv', true);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/users/invites-pending/`, {
            params: parameters,
        });
    }

    noPermissionsReport(csv?: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (csv) {
            parameters = parameters.set('csv', true);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/users/without-permissions/`, {
            params: parameters,
        });
    }

    usersEmailReport(csv?: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (csv) {
            parameters = parameters.set('csv', true);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/users/all/`, {
            params: parameters,
        });
    }

    noRelationshipsReport(csv?: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (csv) {
            parameters = parameters.set('csv', true);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/timelines/without-relationships/`, {
            params: parameters,
        });
    }

    noDOBReport(csv?: boolean): Observable<any> {
        let parameters: HttpParams = new HttpParams();
        if (csv) {
            parameters = parameters.set('csv', true);
        }
        return this.http.get<any>(`${environment.apiUrl}v2/api/reporting/day-to-day-reporting/users/no-dob`, {
            params: parameters,
        });
    }

    getDuplicateAccounts(): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}v2/api/admin/users/similar/`);
    }

    mergeUsers(body: { email: string, first_name: string, last_name: string }): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}v2/api/admin/users/merge/`, body);
    }


}
