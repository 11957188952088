<mat-dialog-content *ngIf="currentMedia" aria-modal="true" class="d-flex flex-col" role="dialog">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title *ngIf="currentMedia?.title">
                    <h3 class="m-0">{{ currentMedia?.title }}</h3>
                </mat-card-title>
                <mat-card-subtitle>
                    <small>{{ currentMedia?.date|date:'short' }}</small>
                </mat-card-subtitle>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button (keyup.enter)="this.dialogRef.close();" class="mat-dialog-close" mat-dialog-close mat-icon-button
                    matTooltip="Close Window">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <ng-container *ngIf="cropping && currentMedia.cropping">
        <div class="text-center w-100 d-flex justify-content-center">
            <div class="w-content d-flex">
                <image-cropper (imageCropped)="imageCropped(currentMedia, $event)" [canvasRotation]="rotation"
                               [imageURL]="currentMedia.image"
                               [maintainAspectRatio]="false"
                               class="cropperControl"
                ></image-cropper>
            </div>
        </div>

    </ng-container>
    <ng-container *ngIf="!cropping">
        <app-media-display (initiateCrop)="initiateCrop()"
                           [croppable]="true"
                           [file]="currentMedia"></app-media-display>
        <form [formGroup]="editItemForm" class="pt-10 fieldContainer">
            <mat-form-field appearance="outline">
                <mat-label>Title</mat-label>
                <input formControlName="title" matInput maxlength="100" placeholder="Title">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Description</mat-label>
                <input formControlName="description" matInput maxlength="255" placeholder="Description">
                <app-emoji-menu [form]="editItemForm" [input]="'description'" matSuffix></app-emoji-menu>
            </mat-form-field>
            <mat-slide-toggle *ngIf="checkIfMemory() && currentMedia.image" class="center" color="primary"
                              formControlName="coverImage">
                Album Cover Image
            </mat-slide-toggle>
        </form>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions *ngIf="this.currentMedia && !cropping" class="flex-space-around">
    <button (keyup.enter)="this.dialogRef.close();" [disabled]="loading" color="warn" mat-dialog-close
            mat-raised-button>
        Cancel
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
    <button (click)="saveMedia()" (keyup.enter)="saveMedia()" [disabled]="editItemForm.invalid || loading"
            color="primary" mat-raised-button>
        Save
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
</mat-dialog-actions>
<mat-card-actions *ngIf="cropping" class="flex-space-around">
    <button (click)="cancelCrop(currentMedia)" (keyup.enter)="cancelCrop(currentMedia)" [disabled]="loading"
            color="warn" mat-raised-button>Cancel
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
    <button (click)="rotateCanvas()" (keyup.enter)="rotateCanvas()" [disabled]="loading" color="primary"
            mat-raised-button>Rotate
        <mat-icon>rotate_right</mat-icon>
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
    <button (click)="saveCrop(currentMedia)" (keyup.enter)="saveCrop(currentMedia)" [disabled]="loading" color="primary"
            mat-raised-button>Save Crop
        <mat-icon *ngIf="loading">
            <mat-spinner color="primary"></mat-spinner>
        </mat-icon>
    </button>
</mat-card-actions>

