<main>
    <div class="d-flex flex-row justify-content-center">
        <h3 class="my-10">
            Resources
        </h3>
    </div>

    <div class="mediumContainer">
        <div *ngIf="loading || (!loading && resources.length == 0 && helpfulLinks.length == 0) "
             class="w-100 d-flex flex-col align-items-center">
            <mat-spinner *ngIf="loading" color="primary"></mat-spinner>
            <h2 *ngIf="!loading && resources.length == 0 && helpfulLinks.length == 0">Sorry no content has been created
                for your user type yet
                please try again later</h2>
        </div>
        <div class="memoriesContainer">
            <mat-card *ngIf="helpfulLinks.length > 0">
                <mat-card-header>
                    <mat-card-title class="mb-10">Helpful Links</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-divider></mat-divider>
                    <mat-list>
                        <mat-list-item (click)="link(resource.link, false)"
                                       (keyup.enter)="link(resource.link, false)"
                                       *ngFor="let resource of helpfulLinks"
                                       class="clickable">
                            <mat-icon matListItemIcon>link</mat-icon>
                            <div matListItemTitle>{{ resource.title }}ss</div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
            <mat-card *ngFor="let resource of resources">
                <mat-card-header>
                    <mat-card-title class="mb-10">{{ resource.name }}</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <mat-divider></mat-divider>
                    <mat-list>
                        <mat-list-item (click)="link(item.content, item.download, item.title)"
                                       (keyup.enter)="link(item.content, item.download, item.title)"
                                       *ngFor="let item of resource.items"
                                       class="clickable">
                            <mat-icon *ngIf="item.download" matListItemIcon>get_app</mat-icon>
                            <mat-icon *ngIf="!item.download" matListItemIcon>link</mat-icon>
                            <div matListItemTitle>{{ item.title }}</div>
                        </mat-list-item>
                    </mat-list>
                </mat-card-content>
            </mat-card>
        </div>

    </div>
</main>
