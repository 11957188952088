<mat-dialog-content aria-modal="true" class="d-flex flex-col" role="dialog">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">{{ title }}</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button (keyup.enter)="this.dialogRef.close();" class="mat-dialog-close" mat-dialog-close mat-icon-button
                    matTooltip="Close Window">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
    <app-manage-access-table *ngIf="permissions && this.id" [canRequest]="true" [child]="user.id"
                             [permissions]="permissions" [timeline]="this.id"></app-manage-access-table>
</mat-dialog-content>
