<mat-dialog-content aria-modal="true" class="d-flex flex-col" role="dialog">
    <div class="flex-row flex-space-between">
        <div>
            <mat-card-header class="p-0">
                <mat-card-title>
                    <h3 class="m-0">Keyboard Shortcuts</h3>
                </mat-card-title>
            </mat-card-header>
        </div>
        <div class="float-end">
            <button (keyup.enter)="this.dialogRef.close();" class="mat-dialog-close" mat-dialog-close mat-icon-button
                    matTooltip="Close Window">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>

    <div class="d-flex flex-col shortcuts">
        <div class="d-flex flex-row align-items-center gap10">
            <div class="d-flex flex-row">
                <img alt="Control Key" class="pr-10" matTooltip="Control Key" src="assets/images/ctrl-key.png">
                <img alt="Shift Key" class="pr-10" matTooltip="Shift Key" src="assets/images/shift-key.png">
                <img alt="Space Key" matTooltip="Space Key" src="assets/images/space-key.png">
            </div>
            Pause/Play Slideshow
        </div>
        <div class="d-flex flex-row align-items-center gap10">
            <div class="d-flex flex-row">
                <img alt="Control Key" class="pr-10" matTooltip="Control Key" src="assets/images/ctrl-key.png">
                <img alt="Shift Key" class="pr-10" matTooltip="Shift Key" src="assets/images/shift-key.png">
                <img alt="Left Arrow Key" matTooltip="Left Arrow Key" src="assets/images/left-key.png">
                <span class="orText">/</span>
                <img alt="Control Key" class="pr-10" matTooltip="Control Key" src="assets/images/ctrl-key.png">
                <img alt="Shift Key" class="pr-10" matTooltip="Shift Key" src="assets/images/shift-key.png">
                <img alt="a Key" matTooltip="a Key" src="assets/images/a-key.png">
            </div>
            Navigate to the memory on the left
        </div>
        <div class="d-flex flex-row align-items-center gap10">
            <div class="d-flex flex-row">
                <img alt="Control Key" class="pr-10" matTooltip="Control Key" src="assets/images/ctrl-key.png">
                <img alt="Shift Key" class="pr-10" matTooltip="Shift Key" src="assets/images/shift-key.png">
                <img alt="Right Arrow Key" matTooltip="Right Arrow Key" src="assets/images/right-key.png">
                <span class="orText">/</span>
                <img alt="Control Key" class="pr-10" matTooltip="Control Key" src="assets/images/ctrl-key.png">
                <img alt="Shift Key" class="pr-10" matTooltip="Shift Key" src="assets/images/shift-key.png">
                <img alt="d Key" matTooltip="d Key" src="assets/images/d-key.png">
            </div>
            Navigate to the memory on the right
        </div>
    </div>

</mat-dialog-content>

